import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import ModelViewer from "./ModelViewer";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const ThreeDModelView = () => {
  const user = useSelector((state) => state.user.currentUser);
  const thisCase = useSelector((state) => state.cases.selectedCase);
  const { id } = useParams();
  const [stlURLs, setStlURLs] = useState([]);
  const [model, setModel] = useState({});
  const [annotations, setAnnotations] = useState([]);
  const [measurements, setMeasurements] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    if (user && id) {
      if (thisCase) {
        const modelData = thisCase.items;
        setModel(modelData);
        const stlURLsArray = [];
        if (modelData.modelInfos) {
          modelData.modelInfos.forEach((info) => {
            if (info.url && info.name) {
              stlURLsArray.push({
                url: info.url,
                name: info.name,
                renderOrder: info.orderNumber ?? 0,
                color: info.color ?? "#d3d3d3",
              });
            }
            setStlURLs(stlURLsArray);
            const annotationsArray = modelData.annotations
              ? Object.keys(modelData.annotations).map((key) => ({
                  id: key,
                  ...modelData.annotations[key],
                  show: false,
                }))
              : [];

            // Extract measurements
            const measurementsArray = modelData.measurements
              ? Object.keys(modelData.measurements).map((key) => ({
                  id: key,
                  ...modelData.measurements[key],
                  show: false, // You can add this to control visibility
                }))
              : [];

            setMeasurements(measurementsArray);
            setAnnotations(annotationsArray);
            setLoading(false);
          });
        }
      }
    }
  }, [id, user, thisCase]);

  return (
    <div className="mx-auto">
      <div className="relative">
        {/* Render ModelViewer component only when loading is false */}
        {!loading && (
          <>
            {stlURLs.length > 0 && (
              <ModelViewer
                stlURLs={stlURLs}
                modelColor={model.color}
                itemId={model?.id}
                annotations={annotations}
                measurements={measurements}
              />
            )}
            <button
              className="absolute top-4 left-4 text-blue-400 px-4 py-2 rounded mb-4 transition-colors duration-300 z-10 hover:bg-blue-500"
              onClick={() => navigate(-1)}
            >
              <ArrowBackIcon />
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default ThreeDModelView;
