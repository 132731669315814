import React from "react";

function NotFound() {
  return (
    <div className="flex items-center justify-center h-screen bg-gray-200">
      <div className="text-center">
        <h1 className="text-4xl font-bold text-gray-800 mb-4">
          404 NOT FOUND!
        </h1>
        <p className="text-gray-600">
          Sorry, the page you are looking for could not be found.
        </p>
      </div>
    </div>
  );
}

export default NotFound;
