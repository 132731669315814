import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  ref as dbRef,
  set,
  update,
  query,
  orderByChild,
  equalTo,
  get,
} from "firebase/database";
import { database, storage } from "../../firebase";
import {
  getDownloadURL,
  uploadBytesResumable,
  deleteObject,
  ref as storageRef,
} from "firebase/storage";
import UploadSTLModal from "../modals/UploadSTLModal";
import UploadDICOMModal from "../modals/UploadDICOMModal";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import useIsMobile from "../../hooks/useIsMobile";
import ActionButtons from "./ActionButtons";
import CaseInformation from "./CaseInformation";
import ModelFiles from "./ModelFiles";
import { Statuses } from "../../constants/statuses";
import CommentCaseModal from "../modals/CommentCaseModal";
import { setSelectedCase } from "../../redux/casesRedux";
import CommentsSection from "./CommentsSection";
import ShareModal from "../modals/ShareModal";
import UserPermissionModal from "../modals/UserPermissionModal";

const CasesPageView = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const selectedCase = useSelector((state) => state.cases.selectedCase);
  const user = useSelector((state) => state.user);

  const [modelInfos, setModelInfos] = useState([]);
  const [showSTLModal, setShowSTLModal] = useState(false);
  const [showDICOMModal, setShowDICOMModal] = useState(false);
  const [showCommentModal, setShowCommentModal] = useState(false);
  const [showShareModal, setShowShareModal] = useState(false);
  const [showUserPermissionModal, setShowUserPermissionModal] = useState(false);
  const [files, setFiles] = useState([]);
  const [titles, setTitles] = useState([]);
  const [progress, setProgress] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [orderNumbers, setOrderNumbers] = useState([]);
  const [colorList, setColorList] = useState([]);
  const isMobile = useIsMobile();
  const [selectedComment, setSelectedComment] = useState(null);
  const [revisionNumbers, setrevisionNumbers] = useState([]);

  const userRole =
    selectedCase?.users?.[user?.currentUser?.id]?.role || "Viewer";

  useEffect(() => {
    if (selectedCase) {
      setModelInfos(Object.values(selectedCase.items?.modelInfos || []));
    } else {
      console.warn("No selected case found.");
    }
  }, [selectedCase, navigate]);

  const handleDelete = async (index) => {
    if (!selectedCase) return;

    const modelInfosRef = dbRef(
      database,
      `cases/${selectedCase.id}/items/modelInfos`
    );

    const updatedModelInfos = modelInfos.filter((_, i) => i !== index);
    const fileToDelete = modelInfos[index];

    try {
      await set(modelInfosRef, updatedModelInfos);

      const updatedCase = {
        ...selectedCase,
        items: {
          ...selectedCase.items,
          modelInfos: updatedModelInfos,
        },
      };
      dispatch(setSelectedCase(updatedCase));

      setModelInfos(updatedModelInfos);

      if (fileToDelete.storageName) {
        const fileRef = storageRef(
          storage,
          `cases/${selectedCase.id}/stlFiles/${fileToDelete.storageName}`
        );
        await deleteObject(fileRef);
      }
    } catch (error) {
      console.error("Error deleting model info or file:", error);
    }
  };

  const handleDownload = (file) => {
    const link = document.createElement("a");
    link.href = file.url;
    link.download = file.name;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleSTLFileSelect = (e) => {
    const selectedFiles = Array.from(e.target.files);

    if (selectedFiles.length === 0) {
      return;
    }

    setFiles(selectedFiles);
    setTitles(
      selectedFiles.map((file) => file.name.split(".").slice(0, -1).join("."))
    );
    setOrderNumbers(selectedFiles.map(() => "0"));
    setrevisionNumbers(selectedFiles.map(() => "1"));
    setColorList(selectedFiles.map(() => "#d3d3d3"));
    setShowSTLModal(true);
    e.target.value = null;
  };

  const handleDICOMFileSelect = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      setFiles([selectedFile]);
      setShowDICOMModal(true);
    }
    e.target.value = null;
  };

  const handleSTLUpload = async (titles, orderNumbers) => {
    setUploading(true);

    const currentUser = user?.currentUser;
    const newModelInfos = [...modelInfos];
    const uploadPromises = files.map((file, index) => {
      return new Promise((resolve, reject) => {
        const uniqueName = `${titles[index]}_${Date.now()}.stl`;
        const uploadRef = storageRef(
          storage,
          `cases/${selectedCase.id}/stlFiles/${uniqueName}`
        );
        const uploadTask = uploadBytesResumable(uploadRef, file);

        uploadTask.on(
          "state_changed",
          (snapshot) => {
            const progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            setProgress((prevProgress) => {
              const newProgress = [...prevProgress];
              newProgress[index] = progress;
              return newProgress;
            });
          },
          (error) => {
            console.error("Upload failed:", error);
            reject(error);
          },
          () => {
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
              const newModelInfo = {
                name: titles[index] || file.name,
                url: downloadURL,
                uploadedBy: `${currentUser?.firstName || "Unknown"} ${
                  currentUser?.lastName || "User"
                }`,
                uploadDate: new Date().toDateString(),
                storageName: uniqueName,
                orderNumber: orderNumbers[index],
                revisionNumber: revisionNumbers[index],
                color: colorList[index],
              };
              newModelInfos.push(newModelInfo);
              resolve(newModelInfo);
            });
          }
        );
      });
    });

    await Promise.all(uploadPromises);

    const modelInfosRef = dbRef(
      database,
      `cases/${selectedCase.id}/items/modelInfos`
    );
    await set(modelInfosRef, newModelInfos);

    const updatedCase = {
      ...selectedCase,
      items: {
        ...selectedCase.items,
        modelInfos: newModelInfos,
      },
    };
    dispatch(setSelectedCase(updatedCase));

    setModelInfos(newModelInfos);

    setUploading(false);
    setSuccess(true);
    setTimeout(() => {
      setSuccess(false);
    }, 2000);
    setShowSTLModal(false);
    setProgress([]);
  };

  const handleDICOMUpload = async () => {
    setUploading(true);

    const file = files[0];
    if (file) {
      const uniqueName = `${file.name}_${Date.now()}`;
      const uploadRef = storageRef(
        storage,
        `cases/${selectedCase.id}/dicomFiles/${uniqueName}`
      );
      const uploadTask = uploadBytesResumable(uploadRef, file);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setProgress([progress]);
        },
        (error) => {
          console.error("Upload failed:", error);
          setUploading(false);
        },
        async () => {
          setUploading(false);
          setSuccess(true);

          const caseRef = dbRef(database, `cases/${selectedCase.id}`);
          await update(caseRef, { status: Statuses.IN_PROGRESS });

          setTimeout(() => {
            setSuccess(false);
            setShowDICOMModal(false);
            setFiles([]);
            setProgress([]);
          }, 1000);
        }
      );
    }
  };

  const handleCommentSubmit = async (comment, caseId) => {
    try {
      const timestamp = selectedComment?.timestamp || Date.now();
      const commentRef = dbRef(
        database,
        `cases/${caseId}/comments/${timestamp}`
      );

      const currentUser = user?.currentUser;
      const newComment = {
        text: comment,
        author: `${currentUser?.firstName || "Unknown"} ${
          currentUser?.lastName || "User"
        }`,
        authorId: currentUser?.id || "Unknown ID",
      };

      if (selectedComment) {
        newComment.editTimestamp = Date.now();
      }

      await set(commentRef, newComment);

      console.log("Comment submitted successfully");

      const updatedSelectedCase = {
        ...selectedCase,
        comments: {
          ...selectedCase.comments,
          [timestamp]: {
            ...newComment,
          },
        },
      };

      dispatch(setSelectedCase(updatedSelectedCase));

      setSelectedComment(null);
      setShowCommentModal(false);
    } catch (error) {
      console.error("Error submitting comment:", error);
    }
  };

  const handleDeleteComment = async (commentTimestamp) => {
    try {
      const commentRef = dbRef(
        database,
        `cases/${selectedCase.id}/comments/${commentTimestamp}`
      );

      await set(commentRef, null);

      const updatedComments = { ...selectedCase.comments };
      delete updatedComments[commentTimestamp];

      const updatedSelectedCase = {
        ...selectedCase,
        comments: updatedComments,
      };

      dispatch(setSelectedCase(updatedSelectedCase));

      console.log(
        `Comment with timestamp ${commentTimestamp} deleted successfully`
      );
    } catch (error) {
      console.error("Error deleting comment:", error);
    }
  };

  const handleEditComment = (commentTimestamp) => {
    const commentToEdit = selectedCase.comments[commentTimestamp];
    if (commentToEdit) {
      setSelectedComment({ ...commentToEdit, timestamp: commentTimestamp });
      setShowCommentModal(true);
    }
  };

  const handleShare = async (email, permission) => {
    try {
      if (email === user.currentUser.email) {
        setShowShareModal(false);
        return;
      }

      console.log("Sharing case with email:", email);
      const usersRef = dbRef(database, "users");
      const emailQuery = query(usersRef, orderByChild("email"), equalTo(email));
      const snapshot = await get(emailQuery);

      if (snapshot.exists()) {
        const userId = Object.keys(snapshot.val())[0];
        const user = snapshot.val()[userId];

        const userInfosRef = dbRef(
          database,
          `cases/${selectedCase.id}/users/${userId}`
        );

        const updatedUserInfo = {
          role: permission,
          name: `${user.firstName} ${user.lastName}`,
        };

        await update(userInfosRef, updatedUserInfo);

        const currentCases = user.cases || [];

        if (!currentCases.includes(selectedCase.id)) {
          const updatedCases = [...currentCases, selectedCase.id];
          const userCasesRef = dbRef(database, `users/${userId}/cases`);
          await set(userCasesRef, updatedCases);
        }

        console.log("User successfully added to the case:", {
          userId,
          role: permission,
          name: `${user.firstName} ${user.lastName}`,
        });

        const updatedCase = {
          ...selectedCase,
          users: {
            ...selectedCase.users,
            [userId]: updatedUserInfo,
          },
        };

        dispatch(setSelectedCase(updatedCase));

        setShowShareModal(false);
      } else {
        alert(
          "No user found with the provided email. Please invite them to the platform first."
        );
        console.log("No user found with the provided email.");
      }
    } catch (error) {
      console.error("Error sharing case:", error);
    }
  };

  const handleRoleChange = async (updatedRoles) => {
    try {
      const usersRef = dbRef(database, `cases/${selectedCase.id}/users`);
      await set(usersRef, updatedRoles);

      const updatedCase = {
        ...selectedCase,
        users: updatedRoles,
      };
      dispatch(setSelectedCase(updatedCase));
    } catch (error) {
      console.error("Error updating roles:", error);
    }
  };

  if (!selectedCase) {
    return <p>Loading case details...</p>;
  }

  //TODO Clicking outside the modal might close the modal.
  return (
    <div className={isMobile ? "p-4" : "p-8"}>
      <button
        onClick={() => navigate(-1)}
        className="text-blue-400 px-4 py-2 rounded mb-4 transition-colors duration-300 hover:bg-blue-500"
      >
        <ArrowBackIcon />
      </button>

      <div
        className={`${
          isMobile
            ? "flex flex-col space-y-2 mb-4"
            : "flex justify-between items-start mb-8"
        }`}
      >
        <div className={isMobile ? "" : "w-2/3"}>
          <CaseInformation selectedCase={selectedCase} isMobile={isMobile} />
        </div>

        <div
          className={`${
            isMobile
              ? "flex flex-col space-y-2"
              : "flex flex-col items-end space-y-2 w-1/3"
          }`}
        >
          <ActionButtons
            isMobile={isMobile}
            modelInfos={modelInfos}
            handleSTLFileSelect={handleSTLFileSelect}
            handleDICOMFileSelect={handleDICOMFileSelect}
            handleViewerClick={() => navigate(`/model/${selectedCase.id}`)}
            handleEditClick={() => navigate("/addoreditcasepage")}
            handleCommentCase={() => setShowCommentModal(true)}
            handleShare={() => setShowShareModal(true)}
            handleUserPermissions={() => setShowUserPermissionModal(true)}
            userRole={userRole}
          />
        </div>
      </div>

      <CommentsSection
        comments={selectedCase.comments}
        onDelete={handleDeleteComment}
        onEdit={handleEditComment}
        user={user?.currentUser}
        role={userRole}
      />

      <ModelFiles
        modelInfos={modelInfos}
        handleDownload={handleDownload}
        handleDelete={handleDelete}
        isMobile={isMobile}
        userRole={userRole}
      />

      {showSTLModal && (
        <UploadSTLModal
          files={files}
          titles={titles}
          progress={progress}
          uploading={uploading}
          success={success}
          onClose={() => setShowSTLModal(false)}
          onUpload={handleSTLUpload}
          orderNumbers={orderNumbers}
          colorList={colorList}
          revisionNumbers={revisionNumbers}
        />
      )}

      {showDICOMModal && (
        <UploadDICOMModal
          progress={progress[0]}
          uploading={uploading}
          success={success}
          onClose={() => setShowDICOMModal(false)}
          onUpload={handleDICOMUpload}
        />
      )}

      {showCommentModal && (
        <CommentCaseModal
          onClose={() => {
            setSelectedComment(null);
            setShowCommentModal(false);
          }}
          caseId={selectedCase.id}
          comment={selectedComment}
          onSubmit={handleCommentSubmit}
        />
      )}

      {showShareModal && (
        <ShareModal
          onClose={() => setShowShareModal(false)}
          onSubmit={handleShare}
        />
      )}

      {showUserPermissionModal && (
        <UserPermissionModal
          onClose={() => setShowUserPermissionModal(false)}
          selectedCase={selectedCase}
          userRole={userRole}
          onRoleChange={handleRoleChange}
        />
      )}
    </div>
  );
};

export default CasesPageView;
