import React, { useState, useEffect } from "react";
import useIsMobile from "../../hooks/useIsMobile";

const CommentCaseModal = ({ onClose, caseId, onSubmit, comment }) => {
  const isMobile = useIsMobile();
  const [commentText, setCommentText] = useState("");

  useEffect(() => {
    if (comment) {
      setCommentText(comment.text);
    }
  }, [comment]);

  const handleCommentChange = (e) => {
    setCommentText(e.target.value);
  };

  const handleSubmit = () => {
    if (commentText.trim() !== "") {
      onSubmit(commentText, caseId);
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div
        className={`bg-white p-8 rounded shadow-lg overflow-y-auto ${
          isMobile ? "w-full max-w-xs mx-4" : "w-1/2"
        }`}
      >
        <h2 className="text-xl font-semibold mb-4">
          {comment ? "Edit Comment" : "Add Comment"}
        </h2>
        <textarea
          value={commentText}
          onChange={handleCommentChange}
          className="w-full p-2 border border-gray-300 rounded mb-4 focus:outline-none focus:ring-2 focus:ring-blue-400"
          placeholder="Write your comment here..."
          rows="5"
          required
        ></textarea>
        <div className="flex justify-end space-x-4 mt-4">
          <button
            onClick={onClose}
            className="bg-gray-500 text-white px-4 py-2 rounded focus:outline-none focus:ring-2 focus:ring-gray-300 hover:bg-gray-600 transition"
          >
            Cancel
          </button>
          <button
            onClick={handleSubmit}
            className="bg-blue-500 text-white px-4 py-2 rounded focus:outline-none focus:ring-2 focus:ring-blue-300 hover:bg-blue-600 transition"
            disabled={!commentText.trim()}
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default CommentCaseModal;
