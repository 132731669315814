const parseDate = (dateString) => {
    if (!dateString) return null;
  
    const parts = dateString.split("/");
    if (parts.length !== 3) return null;
  
    const day = parseInt(parts[0], 10);
    const month = parseInt(parts[1], 10) - 1;
    const year = parseInt(parts[2], 10);
  
    if (
      isNaN(day) ||
      isNaN(month) ||
      isNaN(year) ||
      day < 1 ||
      month < 0 ||
      month > 11 ||
      year < 1000
    ) {
      return null;
    }
  
    return new Date(year, month, day);
  };

export default parseDate;