import React, { useState } from "react";
import { TrashIcon } from "@heroicons/react/solid";
import { PencilIcon } from "@heroicons/react/solid";
import { Roles } from "../../constants/roles";
import CommentDeleteConfirmation from "../modals/CommentDeleteConfirmation";

const CommentsSection = ({ comments, onDelete, onEdit, user, role }) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [commentToDelete, setCommentToDelete] = useState(null);

  if (!comments || Object.keys(comments).length === 0) {
    return null;
  }

  const canDeleteComment = (comment) => {
    return (
      user?.id === comment.authorId ||
      [Roles.ADMIN, Roles.CREATOR].includes(role)
    );
  };

  const canEditComment = (comment) => {
    return user?.id === comment.authorId;
  };

  const handleDeleteClick = (timestamp) => {
    setCommentToDelete(timestamp);
    setShowDeleteModal(true);
  };

  const confirmDeleteComment = () => {
    if (commentToDelete) {
      onDelete(commentToDelete);
      setShowDeleteModal(false);
      setCommentToDelete(null);
    }
  };

  const sortedComments = Object.entries(comments).sort(
    ([timestampA, commentA], [timestampB, commentB]) => {
      return parseInt(timestampB) - parseInt(timestampA);
    }
  );

  return (
    <div className="mb-8">
      <h3 className="text-lg font-semibold mb-2">Comments</h3>
      <div className="space-y-4">
        {sortedComments.map(([timestamp, comment]) => (
          <div
            key={timestamp}
            className="relative p-4 border rounded-lg shadow-sm bg-white"
          >
            {(canDeleteComment(comment) || canEditComment(comment)) && (
              <div className="absolute top-2 right-2 flex space-x-2">
                {canEditComment(comment) && (
                  <button
                    onClick={() => onEdit(timestamp, comment)}
                    className="text-blue-500 hover:text-blue-700"
                    title="Edit Comment"
                  >
                    <PencilIcon className="w-5 h-5" />
                  </button>
                )}
                {canDeleteComment(comment) && (
                  <button
                    onClick={() => handleDeleteClick(timestamp)}
                    className="text-red-500 hover:text-red-700"
                    title="Delete Comment"
                  >
                    <TrashIcon className="w-5 h-5" />
                  </button>
                )}
              </div>
            )}
            <p className="text-sm text-gray-600 mb-1 break-words">
              <strong>{comment.author}</strong> -{" "}
              {new Date(
                parseInt(comment.editTimestamp || timestamp)
              ).toLocaleString()}
              {comment.editTimestamp && (
                <span className="ml-1 text-xs text-gray-500">(edited)</span>
              )}
            </p>
            <p className="text-gray-800 break-words">{comment.text}</p>
          </div>
        ))}
      </div>

      {showDeleteModal && (
        <CommentDeleteConfirmation
          onClose={() => {
            setShowDeleteModal(false);
            setCommentToDelete(null);
          }}
          onConfirm={confirmDeleteComment}
        />
      )}
    </div>
  );
};

export default CommentsSection;
