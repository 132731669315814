import React, { useState } from "react";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import CloseIcon from "@mui/icons-material/Close";

const AnnotationsSidebar = ({
  pins,
  onSelect,
  onDelete,
  onSave,
  itemId,
  handleCheckboxChange,
}) => {
  const [editIndex, setEditIndex] = useState(null);
  const [editedText, setEditedText] = useState("");

  // State to track the visibility toggle for all models
  const [allVisible, setAllVisible] = useState(false);

  // Function to toggle visibility for all models
  const toggleAllModelsVisibility = () => {
    setAllVisible(!allVisible);
    pins.forEach((pin) => {
      handleCheckboxChange(!allVisible, pin.id); // Toggle each model's visibility
    });
  };

  return (
    <div className="absolute right-0 top-10 w-72 md:w-44 h-full bg-gray-100 overflow-y-auto shadow-lg z-20">
      <div className="bg-blue-500 text-white text-center h-12 flex items-center justify-between px-4 font-semibold">
        <button
          onClick={toggleAllModelsVisibility}
          className="px-4 py-1 rounded hover:bg-blue-700 transition duration-150 ease-in-out text-sm font-semibold"
        >
          {allVisible ? "Hide All" : "Show All"}
        </button>
        <span className="flex-grow text-center">ANNOTATIONS</span>
      </div>

      {/* Annotations List */}
      <ul className="list-none m-0 p-0">
        {pins.map((pin, index) => (
          <li
            key={index}
            onClick={() => onSelect(pin)}
            className="cursor-pointer p-4 hover:bg-blue-100 bg-gray-200 border-b-2 border-gray-300 transition-colors duration-150 ease-in-out flex justify-between items-center"
          >
            <div className="flex items-center justify-start w-full">
              {/* Text content */}
              {editIndex === index ? (
                // Editable input when in edit mode
                <input
                  type="text"
                  value={editedText}
                  onChange={(e) => setEditedText(e.target.value)}
                  className="w-full border border-gray-300 rounded px-2 py-1 text-gray-800 focus:outline-none focus:ring focus:border-blue-500"
                  onClick={(e) => e.stopPropagation()} // Prevent triggering onSelect
                />
              ) : (
                // Display annotation text
                <div>
                  <div className="font-medium md:font-normal md:text-sm text-gray-800">
                    {pin.annotation}
                  </div>
                  <div className="text-xs md:font-light text-gray-600 truncate">
                    Annotation {index + 1}
                  </div>
                </div>
              )}
            </div>
            {/* Buttons */}
            <div className="flex items-center">
              {/* Show/Hide Toggle */}
              <label
                className="inline-flex items-center cursor-pointer"
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <input
                  type="checkbox"
                  value={pin.show ? "true" : "false"}
                  className="sr-only peer"
                  checked={pin.show}
                  onChange={(e) => {
                    handleCheckboxChange(e.target.checked, pin.id);
                  }}
                />
                <div className="relative w-7 h-4 md:w-5 md:h-3 bg-gray-200 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full md:after:h-2 md:after:w-2 after:h-3 after:w-3 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
              </label>
              {/* Edit/Save Button */}
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  if (editIndex === index) {
                    // Save logic
                    onSave(editedText, pin.id);
                    setEditIndex(null); // Exit edit mode
                  } else {
                    // Enter edit mode
                    setEditIndex(index);
                    setEditedText(pin.annotation); // Set current annotation text in state
                  }
                }}
                className="px-2 py-1 md:p-0 bg-blue-500 text-white font-semibold md:font-normal text-xs rounded hover:bg-blue-600 transition-colors duration-150 ease-in-out ml-2 md:h-8 md:w-8"
              >
                {editIndex === index ? <SaveIcon /> : <EditIcon />}
              </button>
              {/* Delete/Cancel Button */}
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  if (editIndex === index) {
                    // Cancel edit mode
                    setEditIndex(null);
                  } else {
                    // Delete logic
                    onDelete(index, itemId, pin.id);
                  }
                }}
                className={`px-2 py-1 md:p-0 ${
                  editIndex === index
                    ? "bg-gray-500 hover:bg-gray-600"
                    : "bg-red-500 hover:bg-red-600"
                } text-white font-semibold md:font-normal text-xs rounded transition-colors duration-150 ease-in-out ml-2 md:h-8 md:w-8`}
              >
                {editIndex === index ? <CloseIcon /> : <DeleteOutlineIcon />}
              </button>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default AnnotationsSidebar;
