import React, {
  useRef,
  useEffect,
  useState,
  useMemo,
  useCallback,
} from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  updateModelInfos,
  updateAnnotations,
  updateMeasurements,
} from "../redux/casesRedux";
import { useParams } from "react-router-dom";
import * as THREE from "three";
import { TrackballControls } from "three/examples/jsm/controls/TrackballControls";
import { STLLoader } from "three/examples/jsm/loaders/STLLoader";
import { throttle } from "lodash";
import TWEEN from "@tweenjs/tween.js";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import ModelsSidebar from "./ModelsSidebar";
import AnnotationsSidebar from "./AnnotationsSidebar";
import MeasurementsSidebar from "./MeasurementsSidebar";
import AnnotationModal from "./modals/AnnotationModal";
import MeasurementModal from "./modals/MeasurementModal";
import Tabs from "./Tabs";
import useIsMobile from "../hooks/useIsMobile";
import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";
import { Line2 } from "three/examples/jsm/lines/Line2";
import { LineMaterial } from "three/examples/jsm/lines/LineMaterial";
import { LineGeometry } from "three/examples/jsm/lines/LineGeometry";

import { ref, push, update, child, remove } from "firebase/database";
import { database } from "../firebase";

const ModelViewer = ({
  stlURLs,
  modelColor,
  itemId,
  annotations,
  measurements,
}) => {
  // const user = useSelector((state) => state.user.currentUser);
  const thisCase = useSelector((state) => state.cases.selectedCase);
  const dispatch = useDispatch();
  const { id } = useParams();
  const containerRef = useRef(null);
  const modelRef = useRef(new THREE.Object3D());
  const controlsRef = useRef(null);
  const cameraRef = useRef(null);
  const sceneRef = useRef(null);
  const currentTweenRef = useRef(null);
  const color = useMemo(() => new THREE.Color(modelColor), [modelColor]);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [isAnnotationMode, setIsAnnotationMode] = useState(false);
  const [isDistanceMeasuringMode, setIsDistanceMeasuringMode] = useState(false);
  const [isScalpelMode, setIsScalpelMode] = useState(false);
  const [isAnnotationModalOpen, setIsAnnotationModalOpen] = useState(false);
  const [isMeasurementModalOpen, setIsMeasurementModalOpen] = useState(false);
  const [pathPoints, setPathPoints] = useState([]);
  const [totalDistance, setTotalDistance] = useState(0);
  const [activeTab, setActiveTab] = useState("models");
  const [numOfLoadedModels, setNumOfLoadedModels] = useState(0);
  const [isModelLoading, setIsModelLoading] = useState(true);
  const [tempAnnotationDetails, setTempAnnotationDetails] = useState(null);
  const [pins, setPins] = useState(annotations);
  const [savedMeasurements, setSavedMeasurements] = useState(measurements);
  const [initialCameraPosition, setInitialCameraPosition] = useState(null);
  const [initialCameraTarget, setInitialCameraTarget] = useState(null);
  const [drawPoints, setDrawPoints] = useState([]);
  const [isDrawing, setIsDrawing] = useState(false);
  const [cutHeight, setCutHeight] = useState(10);
  const [selectedModels, setSelectedModels] = useState([]);
  const [isDrillMode, setIsDrillMode] = useState(false);
  const drillBrushRef = useRef(null);
  const [brushActive, setBrushActive] = useState(false);
  const [brushSize, setBrushSize] = useState(1);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const isMobile = useIsMobile();
  const [showOptions, setShowOptions] = useState(false);
  const [processing, setProcessing] = useState(false);

  useEffect(() => {
    if (isMobile) {
      setIsSidebarOpen(false);
    } else {
      setIsSidebarOpen(true);
    }
  }, [isMobile]);

  const initializeBrush = (size, thickness = 5) => {
    const brushSegments = [];
    const segments = 50; // Number of segments for the circle
    for (let i = 0; i < segments; i++) {
      const nexti = (i + 1) % segments;
      const x1 = Math.sin((2 * Math.PI * i) / segments) * size;
      const y1 = Math.cos((2 * Math.PI * i) / segments) * size;
      const x2 = Math.sin((2 * Math.PI * nexti) / segments) * size;
      const y2 = Math.cos((2 * Math.PI * nexti) / segments) * size;
      brushSegments.push(x1, y1, 0, x2, y2, 0); // Flat array for LineGeometry
    }

    const geometry = new LineGeometry();
    geometry.setPositions(brushSegments);

    const material = new LineMaterial({
      color: 0xfb8c00,
      linewidth: thickness, // Line thickness
    });

    material.resolution.set(window.innerWidth, window.innerHeight); // Needed for Line2

    const brush = new Line2(geometry, material);
    return brush;
  };

  useEffect(() => {
    if (isDrillMode) {
      const brush = initializeBrush(brushSize);
      if (drillBrushRef.current) {
        sceneRef.current.remove(drillBrushRef.current);
      }
      sceneRef.current.add(brush);
      drillBrushRef.current = brush;
    } else {
      if (drillBrushRef.current) {
        sceneRef.current.remove(drillBrushRef.current);
        drillBrushRef.current = null;
      }
    }
  }, [isDrillMode, brushSize]);

  useEffect(() => {
    if (isDrillMode) {
      const onMouseMove = (e) => {
        if (e.target.closest(".ui-outside-scene")) {
          return;
        }

        const rect = containerRef.current.getBoundingClientRect();
        const mouse = new THREE.Vector2(
          ((e.clientX - rect.left) / rect.width) * 2 - 1,
          -((e.clientY - rect.top) / rect.height) * 2 + 1
        );

        const raycaster = new THREE.Raycaster();
        raycaster.setFromCamera(mouse, cameraRef.current);
        const visibleObjects = modelRef.current.children.filter(
          (child) => child.visible
        );
        const intersects = raycaster.intersectObjects(visibleObjects, true);

        if (intersects.length > 0) {
          const intersect = intersects[0];
          drillBrushRef.current.position.copy(intersect.point);
          drillBrushRef.current.lookAt(
            intersect.point.clone().add(intersect.face.normal)
          ); // Make brush follow surface normal
          setBrushActive(true);
        } else {
          setBrushActive(false);
        }
      };

      const onMouseDown = (e) => {
        if (e.button !== 0 || e.target.closest(".ui-outside-scene")) {
          return;
        }
        if (!brushActive) return;

        const rect = containerRef.current.getBoundingClientRect();
        const mouse = new THREE.Vector2(
          ((e.clientX - rect.left) / rect.width) * 2 - 1,
          -((e.clientY - rect.top) / rect.height) * 2 + 1
        );

        const raycaster = new THREE.Raycaster();
        raycaster.setFromCamera(mouse, cameraRef.current);
        const visibleObjects = modelRef.current.children.filter(
          (child) => child.visible
        );
        const intersects = raycaster.intersectObjects(visibleObjects, true);

        if (intersects.length > 0) {
          performDrill(intersects[0]);
        }
      };

      const performDrill = (intersect) => {
        const { object } = intersect;
        const geometry = object.geometry;
        const positionAttribute = geometry.attributes.position;
        const normalAttribute = geometry.attributes.normal;

        const positions = positionAttribute.array;
        const normals = normalAttribute.array;

        const verticesToRemove = new Set();

        for (let i = 0; i < positions.length; i += 9) {
          const v1 = new THREE.Vector3(
            positions[i],
            positions[i + 1],
            positions[i + 2]
          );
          const v2 = new THREE.Vector3(
            positions[i + 3],
            positions[i + 4],
            positions[i + 5]
          );
          const v3 = new THREE.Vector3(
            positions[i + 6],
            positions[i + 7],
            positions[i + 8]
          );

          const center = new THREE.Vector3()
            .addVectors(v1, v2)
            .add(v3)
            .divideScalar(3);

          if (center.distanceTo(intersect.point) < brushSize) {
            verticesToRemove.add(i);
            verticesToRemove.add(i + 1);
            verticesToRemove.add(i + 2);
            verticesToRemove.add(i + 3);
            verticesToRemove.add(i + 4);
            verticesToRemove.add(i + 5);
            verticesToRemove.add(i + 6);
            verticesToRemove.add(i + 7);
            verticesToRemove.add(i + 8);
          }
        }

        const newPositions = [];
        const newNormals = [];

        for (let i = 0; i < positions.length; i += 3) {
          if (!verticesToRemove.has(i)) {
            newPositions.push(positions[i], positions[i + 1], positions[i + 2]);
            newNormals.push(normals[i], normals[i + 1], normals[i + 2]);
          }
        }

        const newGeometry = new THREE.BufferGeometry();
        newGeometry.setAttribute(
          "position",
          new THREE.Float32BufferAttribute(newPositions, 3)
        );
        newGeometry.setAttribute(
          "normal",
          new THREE.Float32BufferAttribute(newNormals, 3)
        );

        object.geometry.dispose();
        object.geometry = newGeometry;
      };

      window.addEventListener("mousemove", onMouseMove);
      window.addEventListener("mousedown", onMouseDown);

      return () => {
        window.removeEventListener("mousemove", onMouseMove);
        window.removeEventListener("mousedown", onMouseDown);
      };
    }
  }, [isDrillMode, brushActive, brushSize]);

  const addPoint = useCallback(
    (event) => {
      const rect = containerRef.current.getBoundingClientRect();
      const x = ((event.clientX - rect.left) / rect.width) * 2 - 1;
      const y = -((event.clientY - rect.top) / rect.height) * 2 + 1;

      const raycaster = new THREE.Raycaster();
      raycaster.setFromCamera({ x, y }, cameraRef.current);

      // Find all visible models
      const visibleModels = modelRef.current.children.filter(
        (child) => child.visible
      );

      if (visibleModels.length > 0) {
        // Intersect with all visible models
        const intersects = raycaster.intersectObjects(visibleModels, true);

        if (intersects.length > 0) {
          const intersectPoint = intersects[0].point;

          const geometry = new THREE.SphereGeometry(0.5, 32, 32);
          const material = new THREE.MeshBasicMaterial({
            color: 0x000000,
          });
          const sphere = new THREE.Mesh(geometry, material);
          sphere.position.copy(intersectPoint);
          sceneRef.current.add(sphere);

          // Track the sphere as a temporary point
          if (!sceneRef.current.userData.tempPoints) {
            sceneRef.current.userData.tempPoints = [];
          }
          sceneRef.current.userData.tempPoints.push(sphere);

          setPathPoints((prevPoints) => {
            const updatedPoints = [...prevPoints, sphere];
            calculateAndShowDistance(updatedPoints);

            if (updatedPoints.length > 1) {
              const start = updatedPoints[updatedPoints.length - 2].position;
              const end = updatedPoints[updatedPoints.length - 1].position;

              // Calculate the midpoint and the direction
              const direction = new THREE.Vector3().subVectors(end, start);
              const distance = direction.length();
              const midpoint = new THREE.Vector3()
                .addVectors(start, end)
                .multiplyScalar(0.5);

              // Create a cylinder geometry as the thick straight line
              const lineGeometry = new THREE.CylinderGeometry(
                0.2,
                0.2,
                distance,
                32
              );
              const lineMaterial = new THREE.MeshBasicMaterial({
                color: 0xff0000,
              });
              const line = new THREE.Mesh(lineGeometry, lineMaterial);

              // Rotate and position the cylinder to align with the start and end points
              line.position.copy(midpoint);
              line.lookAt(end);
              line.rotateX(Math.PI / 2);

              sceneRef.current.add(line);

              // Track the line as a temporary line
              if (!sceneRef.current.userData.tempLines) {
                sceneRef.current.userData.tempLines = [];
              }
              sceneRef.current.userData.tempLines.push(line);
            }

            return updatedPoints;
          });
        }
      }
    },
    [cameraRef, modelRef, sceneRef, setPathPoints]
  );

  const closePolygon = useCallback(() => {
    if (pathPoints.length < 3) return;

    setPathPoints((prevPoints) => {
      // Step 1: Check if the polygon is already closed
      if (prevPoints.length > 2) {
        const lastPoint = prevPoints[prevPoints.length - 1].position;
        const firstPoint = prevPoints[0].position;

        if (lastPoint.equals(firstPoint)) {
          return prevPoints; // Polygon is already closed
        }
      }

      // Step 2: Add the first point to the end of the list to close the polygon
      const newPoints = [...prevPoints];
      const firstPointClone = prevPoints[0].clone(); // Clone to avoid mutating original point
      newPoints.push(firstPointClone);

      // Step 3: Add visual lines between consecutive points (including closing line)
      for (let i = 1; i < newPoints.length; i++) {
        const pointA = newPoints[i - 1].position;
        const pointB = newPoints[i].position;

        // Calculate the midpoint and direction
        const direction = new THREE.Vector3().subVectors(pointB, pointA);
        const distance = direction.length();
        const midpoint = new THREE.Vector3()
          .addVectors(pointA, pointB)
          .multiplyScalar(0.5);

        // Create a cylinder geometry for the line
        const lineGeometry = new THREE.CylinderGeometry(0.2, 0.2, distance, 32);
        const lineMaterial = new THREE.MeshBasicMaterial({ color: 0xff0000 });
        const line = new THREE.Mesh(lineGeometry, lineMaterial);

        // Rotate and position the cylinder to align with the segment
        line.position.copy(midpoint);
        line.lookAt(pointB);
        line.rotateX(Math.PI / 2);

        // Add the line to the scene as a temporary entity
        if (!sceneRef.current.userData.tempLines) {
          sceneRef.current.userData.tempLines = [];
        }
        sceneRef.current.add(line);
        sceneRef.current.userData.tempLines.push(line);
      }

      // Step 4: Recalculate the total distance
      let totalDistance = 0;
      for (let i = 1; i < newPoints.length; i++) {
        const pointA = newPoints[i - 1].position;
        const pointB = newPoints[i].position;
        totalDistance += pointA.distanceTo(pointB);
      }

      setTotalDistance(totalDistance.toFixed(2)); // Update total distance
      return newPoints; // Update the points list with the closed polygon
    });
  }, [sceneRef, setPathPoints, setTotalDistance, pathPoints]);

  const clearMeasurements = useCallback(() => {
    if (!sceneRef.current || !sceneRef.current.userData) {
      console.warn("Scene or userData is not initialized.");
      return;
    }

    // Clear the temporary points
    if (sceneRef.current.userData.tempPoints) {
      sceneRef.current.userData.tempPoints.forEach((sphere) => {
        if (sphere) {
          sceneRef.current.remove(sphere);

          // Dispose geometry and material only if they exist
          if (sphere.geometry) {
            sphere.geometry.dispose();
          }
          if (sphere.material) {
            sphere.material.dispose();
          }
        }
      });

      // Reset the temporary points array
      sceneRef.current.userData.tempPoints = [];
    }

    // Clear the temporary lines
    if (sceneRef.current.userData.tempLines) {
      sceneRef.current.userData.tempLines.forEach((line) => {
        if (line) {
          sceneRef.current.remove(line);

          // Dispose geometry and material only if they exist
          if (line.geometry) {
            line.geometry.dispose();
          }
          if (line.material) {
            line.material.dispose();
          }
        }
      });

      // Reset the temporary lines array
      sceneRef.current.userData.tempLines = [];
    }

    // Reset pathPoints and total distance
    setPathPoints([]);
    setTotalDistance(0);
  }, [sceneRef, setPathPoints, setTotalDistance]);

  const savePathPoints = async (name) => {
    if (pathPoints.length === 0) {
      console.warn("No points to save!");
      return;
    }

    // Extract point data for the new measurement
    const pointsData = pathPoints.map((point, index) => ({
      id: index + 1,
      position: {
        x: point.position.x,
        y: point.position.y,
        z: point.position.z,
      },
    }));

    const pathData = {
      name,
      points: pointsData,
      totalDistance,
    };

    // Save the new measurement to Firebase
    const newDistanceId = push(
      child(ref(database), `cases/${id}/items/measurements`)
    ).key;

    await update(
      ref(database, `cases/${id}/items/measurements/${newDistanceId}`),
      pathData
    );

    // Clear all path points
    clearMeasurements();
    setSavedMeasurements((prevMeasurements) => [
      ...prevMeasurements,
      { ...pathData, show: true, id: newDistanceId },
    ]);
    renderSavedMeasurement({ ...pathData, id: newDistanceId });

    dispatch(
      updateMeasurements({
        actionType: "add",
        measurementId: newDistanceId,
        measurementData: { ...pathData, show: true },
      })
    );
  };

  const calculateOptimalCameraPosition = (annotationPosition) => {
    const boundingBox = new THREE.Box3().setFromObject(modelRef.current);
    const center = boundingBox.getCenter(new THREE.Vector3());
    const size = boundingBox.getSize(new THREE.Vector3());
    const maxDimension = Math.max(size.x, size.y, size.z);

    // Calculate the distance from the annotation based on the model size
    const distance = maxDimension;
    const height = maxDimension;

    // Calculate new camera position
    const direction = new THREE.Vector3()
      .subVectors(annotationPosition, center)
      .normalize();
    const newPosition = new THREE.Vector3().addVectors(
      annotationPosition,
      direction.multiplyScalar(distance)
    );
    newPosition.y += height; // Adjust height to look from above

    return newPosition;
  };

  function moveToAnnotation(annotationPosition) {
    if (!sceneRef.current || !modelRef.current) {
      console.error("Scene or model is not ready.");
      return;
    }

    /*     if (currentTweenRef.current) {
      currentTweenRef.current.stop();
    } */

    // Calculate optimal positions for both the camera and its target based on the annotation.
    const optimalCameraPosition =
      calculateOptimalCameraPosition(annotationPosition);
    const cameraTargetPosition = annotationPosition; // Directly focusing on the annotation.

    const initialCameraPosition = {
      x: cameraRef.current.position.x,
      y: cameraRef.current.position.y,
      z: cameraRef.current.position.z,
    };

    const initialTargetPosition = {
      x: controlsRef.current.target.x,
      y: controlsRef.current.target.y,
      z: controlsRef.current.target.z,
    };

    // Interpolating camera position
    const cameraTween = new TWEEN.Tween(initialCameraPosition)
      .to(
        {
          x: optimalCameraPosition.x,
          y: optimalCameraPosition.y,
          z: optimalCameraPosition.z,
        },
        1000
      )
      .easing(TWEEN.Easing.Quadratic.Out)
      .onUpdate(({ x, y, z }) => cameraRef.current.position.set(x, y, z))
      .start();

    // Interpolating camera target
    const targetTween = new TWEEN.Tween(initialTargetPosition)
      .to(
        {
          x: cameraTargetPosition.x,
          y: cameraTargetPosition.y,
          z: cameraTargetPosition.z,
        },
        1000
      )
      .easing(TWEEN.Easing.Quadratic.Out)
      .onUpdate(({ x, y, z }) => controlsRef.current.target.set(x, y, z))
      .onComplete(() => {
        controlsRef.current.update();
        currentTweenRef.current = null; // Ensure to clear the reference once animation completes
      })
      .start();

    // Keep a reference to the current tween for potential stopping
    currentTweenRef.current = { cameraTween, targetTween };
  }

  // Load Models
  useEffect(() => {
    const loadModels = () => {
      // Create the scene
      const scene = new THREE.Scene();
      sceneRef.current = scene;
      scene.add(modelRef.current);

      // Ambient light for soft overall illumination
      const ambientLight = new THREE.AmbientLight(0xffffff, 0.3); // Low intensity
      scene.add(ambientLight);

      // Directional light 1
      const directionalLight1 = new THREE.DirectionalLight(0xffffff, 1);
      directionalLight1.position.set(2, 2, 2); // Positioned diagonally above the model
      // directionalLight1.castShadow = true; // Enable shadows
      scene.add(directionalLight1);

      // Directional light 2
      const directionalLight2 = new THREE.DirectionalLight(0xffffff, 0.5);
      directionalLight2.position.set(-2, -2, 2); // Positioned diagonally opposite to the key light
      scene.add(directionalLight2);

      // Directional light 3
      const directionalLight3 = new THREE.DirectionalLight(0xffffff, 0.5);
      directionalLight3.position.set(0, 3, -2); // Positioned behind and above the model
      scene.add(directionalLight3);

      // Directional light 4
      const directionalLight4 = new THREE.DirectionalLight(0xffffff, 0.5);
      directionalLight4.position.set(1, -1, 1); // Positioned behind and above the model
      scene.add(directionalLight4);

      const loader = new STLLoader();
      const promises = [];
      const tempModelRefs = Array(stlURLs.length).fill(null);
      let count = 0;
      stlURLs.forEach((stl, index) => {
        const url = stl.url;
        // Create a promise for each model load operation
        const promise = new Promise((resolve, reject) => {
          loader.load(
            url,
            (geometry) => {
              // Create the mesh
              const material = new THREE.MeshStandardMaterial({
                color: stl.color || 0xd3d3d3,
                transparent: true,
                opacity: 1,
                clippingPlanes: [],
              });
              const mesh = new THREE.Mesh(geometry, material);
              mesh.name = stl.name;
              mesh.renderOrder = stl.renderOrder;
              tempModelRefs[index] = mesh;
              count++;
              setNumOfLoadedModels(count);
              resolve();
            },
            undefined,
            reject
          );
        });
        promises.push(promise);
      });

      Promise.all(promises)
        .then(() => {
          tempModelRefs.forEach((mesh) => {
            if (mesh) modelRef.current.add(mesh);
          });

          setIsModelLoading(false);
          // Create a camera
          const camera = new THREE.PerspectiveCamera(
            50,
            containerRef.current.clientWidth /
              containerRef.current.clientHeight,
            0.1,
            10000
          );
          cameraRef.current = camera; // Store the camera reference

          // Create a renderer
          const renderer = new THREE.WebGLRenderer({ alpha: true });
          renderer.setSize(
            containerRef.current.clientWidth,
            containerRef.current.clientHeight
          );
          renderer.localClippingEnabled = true;
          renderer.setClearColor(0xbbbbbb);
          containerRef.current.appendChild(renderer.domElement);

          // Create controls
          const controls = new TrackballControls(
            cameraRef.current,
            renderer.domElement
          );
          controlsRef.current = controls;
          controls.rotateSpeed = 5.0;
          controls.zoomSpeed = 2;
          controls.panSpeed = 0.8;
          controls.staticMoving = true;
          controls.dynamicDampingFactor = 0.3;

          // Configure mouse buttons
          /* controls.mouseButtons = {
            LEFT: THREE.MOUSE.PAN,
            MIDDLE: THREE.MOUSE.ZOOM,
            RIGHT: THREE.MOUSE.ROTATE,
          }; */

          // Calculate the bounding box of the model
          const boundingBox = new THREE.Box3().setFromObject(modelRef.current);

          //space center calculations
          const center = new THREE.Vector3();
          boundingBox.getCenter(center);

          const size = boundingBox.getSize(new THREE.Vector3());
          const maxDimension = Math.max(size.x, size.y, size.z);
          const distance = maxDimension * 2;

          cameraRef.current.position.set(
            center.x,
            center.y,
            center.z + distance
          );
          controlsRef.current.target.set(center.x, center.y, center.z);

          // Set the initial camera position and target
          setInitialCameraPosition({
            x: cameraRef.current.position.x,
            y: cameraRef.current.position.y,
            z: cameraRef.current.position.z,
          });
          setInitialCameraTarget({
            x: controlsRef.current.target.x,
            y: controlsRef.current.target.y,
            z: controlsRef.current.target.z,
          });

          // Render the scene
          function animate() {
            requestAnimationFrame(animate);
            TWEEN.update();
            controlsRef.current.update();
            renderer.render(sceneRef.current, cameraRef.current);
          }

          animate();

          // Update the renderer size when the window is resized
          const handleResize = () => {
            if (containerRef.current) {
              renderer.setSize(
                containerRef.current.clientWidth,
                containerRef.current.clientHeight
              );
              camera.aspect =
                containerRef.current.clientWidth /
                containerRef.current.clientHeight;
              camera.updateProjectionMatrix();
            }
          };
          window.addEventListener("resize", handleResize);

          // Clean up event listeners on component unmount
          return () => {
            clearTimeout(timer);
            window.removeEventListener("resize", handleResize);
            scene.children.forEach((child) => {
              if (child instanceof THREE.Mesh) {
                child.geometry.dispose();
                child.material.dispose();
              }
            });
            renderer.dispose();
          };
        })
        .catch((error) => {
          // Handle errors if any model fails to load
          console.error("Error loading models:", error);
        });
    };

    const timer = setTimeout(() => {
      if (numOfLoadedModels === 0) {
        loadModels();
      }
    }, 1000);
    return () => clearTimeout(timer);
  }, [color, stlURLs, numOfLoadedModels]);

  // Mouse event listener for Annotation mode
  useEffect(() => {
    const currentContainer = containerRef.current;

    let clickStartPos = { x: null, y: null };

    const onMouseDown = (event) => {
      if (event.button !== 0) return;
      clickStartPos = { x: event.clientX, y: event.clientY };
    };

    const onMouseUp = (event) => {
      if (event.button !== 0) return;
      const clickEndPos = { x: event.clientX, y: event.clientY };
      const dx = clickEndPos.x - clickStartPos.x;
      const dy = clickEndPos.y - clickStartPos.y;
      const distance = Math.sqrt(dx * dx + dy * dy);

      // Check if the click is on a UI element
      if (event.target.closest(".ui-outside-scene")) {
        return;
      }

      if (distance < 5 && isAnnotationMode) {
        handleAnnotationClick(event);
      }
    };

    const handleAnnotationClick = (event) => {
      if (tempAnnotationDetails) return;

      event.preventDefault();

      const container = containerRef.current;
      const rect = container.getBoundingClientRect(); // Get the position and size of the container

      // Adjust the mouse coordinates to account for the container's position and size
      const mouse = new THREE.Vector2(
        ((event.clientX - rect.left) / rect.width) * 2 - 1,
        -((event.clientY - rect.top) / rect.height) * 2 + 1
      );

      const raycaster = new THREE.Raycaster();
      raycaster.setFromCamera(mouse, cameraRef.current);

      // Find all visible models
      const visibleModels = modelRef.current.children.filter(
        (child) => child.visible
      );

      if (visibleModels.length > 0) {
        // Intersect with all visible models
        const intersects = raycaster.intersectObjects(visibleModels, true);

        if (intersects.length > 0) {
          const point = intersects[0].point;
          setTempAnnotationDetails({ x: point.x, y: point.y, z: point.z });
          setIsAnnotationModalOpen(true);
        }
      }
    };

    if (currentContainer) {
      currentContainer.addEventListener("mousedown", onMouseDown);
      currentContainer.addEventListener("mouseup", onMouseUp);
    }

    return () => {
      if (currentContainer) {
        currentContainer.removeEventListener("mousedown", onMouseDown);
        currentContainer.removeEventListener("mouseup", onMouseUp);
      }
    };
  }, [isAnnotationMode, tempAnnotationDetails]);

  // Updated Scalpel mode event handlers
  useEffect(() => {
    if (!isScalpelMode) return; // Only add event listeners when Scalpel mode is active

    const handleMouseDown = (event) => {
      // Check if the click is on a UI element
      if (event.target.closest(".ui-outside-scene")) {
        return;
      }

      if (event.button !== 0) return;

      const rect = containerRef.current.getBoundingClientRect();
      const x = ((event.clientX - rect.left) / rect.width) * 2 - 1;
      const y = -((event.clientY - rect.top) / rect.height) * 2 + 1;

      const raycaster = new THREE.Raycaster();
      raycaster.setFromCamera({ x, y }, cameraRef.current);

      // Find all visible models
      const visibleModels = modelRef.current.children.filter(
        (child) => child.visible
      );

      if (visibleModels.length > 0) {
        const intersects = raycaster.intersectObjects(visibleModels, true);

        if (intersects.length > 0) {
          const intersectPoint = intersects[0].point;
          setDrawPoints([intersectPoint]);
          setIsDrawing(true);

          // Disable rotation when starting to draw
          controlsRef.current.noRotate = true;
        }
      }
    };

    const handleMouseMove = throttle((event) => {
      // Check if the event is on a UI element
      if (event.target.closest(".ui-outside-scene")) {
        return;
      }

      if (!isDrawing) return;

      const rect = containerRef.current.getBoundingClientRect();
      const x = ((event.clientX - rect.left) / rect.width) * 2 - 1;
      const y = -((event.clientY - rect.top) / rect.height) * 2 + 1;

      const raycaster = new THREE.Raycaster();
      raycaster.setFromCamera({ x, y }, cameraRef.current);

      // Find all visible models
      const visibleModels = modelRef.current.children.filter(
        (child) => child.visible
      );

      if (visibleModels.length > 0) {
        const intersects = raycaster.intersectObjects(visibleModels, true);

        if (intersects.length > 0) {
          const intersectPoint = intersects[0].point;

          // Only add the new point if it's far enough from the last point
          const MIN_DISTANCE = 0.2; // Adjust this value as needed
          if (
            drawPoints.length === 0 ||
            drawPoints[drawPoints.length - 1].distanceTo(intersectPoint) >
              MIN_DISTANCE
          ) {
            setDrawPoints((prevPoints) => [...prevPoints, intersectPoint]);

            // Draw the line
            const points = [...drawPoints, intersectPoint];
            // Create a curve from the points
            const curve = new THREE.CatmullRomCurve3(points);

            // Create a tube geometry based on the curve
            const tubeGeometry = new THREE.TubeGeometry(
              curve,
              64,
              0.5,
              8,
              true
            );
            const tubeMaterial = new THREE.MeshBasicMaterial({
              color: 0xff0000,
            });
            const line = new THREE.Mesh(tubeGeometry, tubeMaterial);

            // Remove old line if exists
            if (sceneRef.current.userData.currentDrawingLine) {
              sceneRef.current.remove(
                sceneRef.current.userData.currentDrawingLine
              );
            }

            sceneRef.current.add(line);
            sceneRef.current.userData.currentDrawingLine = line;
          }
        }
      }
    }, 100);

    const handleMouseUp = (event) => {
      // Check if the event is on a UI element
      if (event.target.closest(".ui-outside-scene")) {
        return;
      }

      if (!isDrawing) return;

      setIsDrawing(false);

      // Enable rotation when finished drawing
      controlsRef.current.noRotate = false;
    };

    const handleTouchStart = (event) => {
      // Check if the touch is on a UI element
      if (event.target.closest(".ui-outside-scene")) {
        return;
      }

      // Prevent default behavior to avoid interfering with touch scrolling
      event.preventDefault();

      const touch = event.touches[0];
      const rect = containerRef.current.getBoundingClientRect();
      const x = ((touch.clientX - rect.left) / rect.width) * 2 - 1;
      const y = -((touch.clientY - rect.top) / rect.height) * 2 + 1;

      const raycaster = new THREE.Raycaster();
      raycaster.setFromCamera({ x, y }, cameraRef.current);

      // Find all visible models
      const visibleModels = modelRef.current.children.filter(
        (child) => child.visible
      );

      if (visibleModels.length > 0) {
        const intersects = raycaster.intersectObjects(visibleModels, true);

        if (intersects.length > 0) {
          const intersectPoint = intersects[0].point;
          setDrawPoints([intersectPoint]);
          setIsDrawing(true);

          // Disable rotation when starting to draw
          controlsRef.current.noRotate = true;
        }
      }
    };

    const handleTouchMove = throttle((event) => {
      // Check if the touch is on a UI element
      if (event.target.closest(".ui-outside-scene")) {
        return;
      }

      // Prevent default behavior to avoid interfering with touch scrolling
      event.preventDefault();

      if (!isDrawing) return;

      const touch = event.touches[0];
      const rect = containerRef.current.getBoundingClientRect();
      const x = ((touch.clientX - rect.left) / rect.width) * 2 - 1;
      const y = -((touch.clientY - rect.top) / rect.height) * 2 + 1;

      const raycaster = new THREE.Raycaster();
      raycaster.setFromCamera({ x, y }, cameraRef.current);

      // Find all visible models
      const visibleModels = modelRef.current.children.filter(
        (child) => child.visible
      );

      if (visibleModels.length > 0) {
        const intersects = raycaster.intersectObjects(visibleModels, true);

        if (intersects.length > 0) {
          const intersectPoint = intersects[0].point;

          // Only add the new point if it's far enough from the last point
          const MIN_DISTANCE = 0.2; // Adjust this value as needed
          if (
            drawPoints.length === 0 ||
            drawPoints[drawPoints.length - 1].distanceTo(intersectPoint) >
              MIN_DISTANCE
          ) {
            setDrawPoints((prevPoints) => [...prevPoints, intersectPoint]);

            // Draw the line
            const points = [...drawPoints, intersectPoint];
            // Create a curve from the points
            const curve = new THREE.CatmullRomCurve3(points);

            // Create a tube geometry based on the curve
            const tubeGeometry = new THREE.TubeGeometry(
              curve,
              64,
              0.1,
              8,
              true
            );
            const tubeMaterial = new THREE.MeshBasicMaterial({
              color: 0xff0000,
            });
            const line = new THREE.Mesh(tubeGeometry, tubeMaterial);

            // Remove old line if exists
            if (sceneRef.current.userData.currentDrawingLine) {
              sceneRef.current.remove(
                sceneRef.current.userData.currentDrawingLine
              );
            }

            sceneRef.current.add(line);
            sceneRef.current.userData.currentDrawingLine = line;
          }
        }
      }
    }, 100);

    const handleTouchEnd = (event) => {
      // Check if the touch is on a UI element
      if (event.target.closest(".ui-outside-scene")) {
        return;
      }

      // Prevent default behavior
      event.preventDefault();

      if (!isDrawing) return;

      setIsDrawing(false);

      // Enable rotation when finished drawing
      controlsRef.current.noRotate = false;
    };

    // Reference to the DOM element for the 3D scene
    const currentContainer = containerRef.current;

    // Add event listeners to the container element instead of window
    if (currentContainer) {
      currentContainer.addEventListener("mousedown", handleMouseDown);
      currentContainer.addEventListener("mousemove", handleMouseMove);
      currentContainer.addEventListener("mouseup", handleMouseUp);

      currentContainer.addEventListener("touchstart", handleTouchStart, {
        passive: false,
      });
      currentContainer.addEventListener("touchmove", handleTouchMove, {
        passive: false,
      });
      currentContainer.addEventListener("touchend", handleTouchEnd);
    }

    return () => {
      // Remove event listeners from the container
      if (currentContainer) {
        currentContainer.removeEventListener("mousedown", handleMouseDown);
        currentContainer.removeEventListener("mousemove", handleMouseMove);
        currentContainer.removeEventListener("mouseup", handleMouseUp);

        currentContainer.removeEventListener("touchstart", handleTouchStart);
        currentContainer.removeEventListener("touchmove", handleTouchMove);
        currentContainer.removeEventListener("touchend", handleTouchEnd);
      }

      handleMouseMove.cancel();
      handleTouchMove.cancel();
    };
  }, [isScalpelMode, isDrawing, drawPoints]);

  useEffect(() => {
    const currentAnnotations = annotations;
    const timeoutId = setTimeout(() => {
      const newPins = currentAnnotations.map((annotation) => {
        const position = new THREE.Vector3(
          annotation.coordinates.x,
          annotation.coordinates.y,
          annotation.coordinates.z
        );

        const pinMesh = createPin(position);
        return { ...annotation, mesh: pinMesh };
      });
      setPins(newPins);
    }, 1000);
    return () => clearTimeout(timeoutId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSaveMeasurements = (name) => {
    savePathPoints(name);
    setIsMeasurementModalOpen(false);
  };

  const handleDeleteMeasurement = (index) => {
    const measurementToDelete = savedMeasurements[index];

    if (!measurementToDelete) {
      console.warn("No measurement found at the given index.");
      return;
    }

    // 1. Clear from the scene if shown
    if (measurementToDelete.show) {
      clearSavedMeasurement(measurementToDelete);
    }

    // 2. Delete from Firebase DB
    const measurementRef = ref(
      database,
      `cases/${id}/items/measurements/${measurementToDelete.id}`
    );
    remove(measurementRef);

    // 3. Update state and global state
    const updatedMeasurements = savedMeasurements.filter((_, i) => i !== index);
    setSavedMeasurements(updatedMeasurements);

    dispatch(
      updateMeasurements({
        actionType: "delete",
        measurementId: measurementToDelete.id,
      })
    );
  };

  const handleSaveMeasurementText = (measurementText, measurementId) => {
    const measurementRef = ref(
      database,
      `cases/${id}/items/measurements/${measurementId}`
    );

    update(measurementRef, { name: measurementText });

    setSavedMeasurements((prevMeasurements) =>
      prevMeasurements.map((measurement) =>
        measurement.id === measurementId
          ? { ...measurement, name: measurementText }
          : measurement
      )
    );

    // Dispatch the generic updateMeasurements action
    dispatch(
      updateMeasurements({
        actionType: "update",
        measurementId,
        measurementData: { name: measurementText },
      })
    );
  };

  const handleMeasurementShowAndHide = (checked, id) => {
    setSavedMeasurements((prevMeasurements) => {
      const updatedMeasurements = prevMeasurements.map((measurement) => {
        if (measurement.id === id) {
          if (checked) {
            // Render the measurement if visibility is toggled on
            renderSavedMeasurement(measurement);
          } else {
            // Clear the measurement if visibility is toggled off
            clearSavedMeasurement(measurement);
          }
          return { ...measurement, show: checked }; // Update visibility state
        }
        return measurement; // No changes for other measurements
      });
      return updatedMeasurements;
    });
  };

  const renderSavedMeasurement = (measurement) => {
    if (!sceneRef.current) {
      console.warn("Scene is not ready yet.");
      return;
    }

    const points = [];

    // Render points
    measurement.points.forEach((pointData) => {
      const geometry = new THREE.SphereGeometry(0.5, 32, 32);
      const material = new THREE.MeshBasicMaterial({ color: 0x000000 });
      const sphere = new THREE.Mesh(geometry, material);
      sphere.position.set(
        pointData.position.x,
        pointData.position.y,
        pointData.position.z
      );
      sceneRef.current.add(sphere);
      points.push(sphere);

      // Track the point in userData
      if (!sceneRef.current.userData.points) {
        sceneRef.current.userData.points = [];
      }
      sceneRef.current.userData.points.push(sphere);
    });

    // Render lines between points
    for (let i = 0; i < points.length - 1; i++) {
      const start = points[i].position;
      const end = points[i + 1].position;

      const direction = new THREE.Vector3().subVectors(end, start);
      const distance = direction.length();
      const midpoint = new THREE.Vector3()
        .addVectors(start, end)
        .multiplyScalar(0.5);

      const lineGeometry = new THREE.CylinderGeometry(0.2, 0.2, distance, 32);
      const lineMaterial = new THREE.MeshBasicMaterial({
        color: 0xff0000,
      });
      const line = new THREE.Mesh(lineGeometry, lineMaterial);

      line.position.copy(midpoint);
      line.lookAt(end);
      line.rotateX(Math.PI / 2);

      sceneRef.current.add(line);

      // Track the line in userData
      if (!sceneRef.current.userData.lines) {
        sceneRef.current.userData.lines = [];
      }
      sceneRef.current.userData.lines.push(line);
    }
  };

  const clearSavedMeasurement = (measurement) => {
    if (!sceneRef.current || !sceneRef.current.userData) return;

    // Remove points
    if (sceneRef.current.userData.points) {
      sceneRef.current.userData.points =
        sceneRef.current.userData.points.filter((point) => {
          const pointPosition = point.position;
          const isPartOfMeasurement = measurement.points.some(
            (pointData) =>
              pointData.position.x === pointPosition.x &&
              pointData.position.y === pointPosition.y &&
              pointData.position.z === pointPosition.z
          );
          if (isPartOfMeasurement) {
            sceneRef.current.remove(point); // Remove from scene
            return false; // Exclude from userData.points
          }
          return true; // Keep in userData.points
        });
    }

    // Remove lines
    if (sceneRef.current.userData.lines) {
      sceneRef.current.userData.lines = sceneRef.current.userData.lines.filter(
        (line) => {
          const isPartOfMeasurement = measurement.points.some(
            (pointData, index) => {
              if (index === measurement.points.length - 1) return false;
              const start = new THREE.Vector3(
                pointData.position.x,
                pointData.position.y,
                pointData.position.z
              );
              const end = new THREE.Vector3(
                measurement.points[index + 1].position.x,
                measurement.points[index + 1].position.y,
                measurement.points[index + 1].position.z
              );
              const lineMidpoint = new THREE.Vector3()
                .addVectors(start, end)
                .multiplyScalar(0.5);
              return line.position.equals(lineMidpoint);
            }
          );
          if (isPartOfMeasurement) {
            sceneRef.current.remove(line); // Remove from scene
            return false; // Exclude from userData.lines
          }
          return true; // Keep in userData.lines
        }
      );
    }
  };

  const handleSaveAnnotation = async (
    annotationText,
    initialPosition,
    itemId
  ) => {
    if (tempAnnotationDetails) {
      const pinMesh = createPin(tempAnnotationDetails);

      const newPinId = push(
        child(ref(database), `cases/${id}/items/annotations`)
      ).key;

      const newPin = {
        coordinates: tempAnnotationDetails,
        annotation: annotationText,
      };

      update(
        ref(database, `cases/${id}/items/annotations/${newPinId}`),
        newPin
      );

      const newPinItem = {
        ...newPin,
        id: newPinId,
        mesh: pinMesh,
        show: true,
      };

      setPins((prevPins) => [...prevPins, newPinItem]);
      setIsAnnotationModalOpen(false);
      setTempAnnotationDetails(null);

      // Dispatch action to update global state
      dispatch(
        updateAnnotations({
          actionType: "add",
          annotationId: newPinId,
          annotationData: newPin,
        })
      );
    }
  };

  const handleDeleteAnnotation = (index, itemId, annotationId) => {
    const targetPin = pins[index];

    if (targetPin && targetPin.mesh) {
      targetPin.mesh.geometry.dispose();
      targetPin.mesh.material.dispose();
      sceneRef.current.remove(targetPin.mesh);
    }

    const updatedPins = [...pins];
    updatedPins.splice(index, 1);
    setPins(updatedPins);

    const annotationRef = ref(
      database,
      `cases/${id}/items/annotations/${annotationId}`
    );

    remove(annotationRef);

    // Dispatch the generic updateAnnotations action
    dispatch(
      updateAnnotations({
        actionType: "delete",
        annotationId,
      })
    );
  };

  const handleSaveAnnotationText = (annotationText, annotationId) => {
    const annotationRef = ref(
      database,
      `cases/${id}/items/annotations/${annotationId}`
    );

    update(annotationRef, { annotation: annotationText });

    setPins((prevPins) =>
      prevPins.map((pin) =>
        pin.id === annotationId ? { ...pin, annotation: annotationText } : pin
      )
    );

    // Dispatch the generic updateAnnotations action
    dispatch(
      updateAnnotations({
        actionType: "update",
        annotationId,
        annotationData: { annotation: annotationText },
      })
    );
  };

  useEffect(() => {
    const updatePinsPosition = () => {
      pins.forEach((pin, index) => {
        // Get the position of the pin in world coordinates
        const worldPosition = new THREE.Vector3(
          pin.coordinates.x,
          pin.coordinates.y,
          pin.coordinates.z
        );

        // Project the world coordinates to screen coordinates
        const screenPosition = worldPosition.project(cameraRef.current);

        // Convert screen coordinates to CSS pixels
        const x =
          ((screenPosition.x + 1) / 2) * containerRef.current.clientWidth;
        const y =
          ((-screenPosition.y + 1) / 2) * containerRef.current.clientHeight;

        // Update the position of the pin if the element exists
        const pinElement = document.getElementById(`pin-${index}`);
        if (pinElement) {
          pinElement.style.top = `${y}px`;
          pinElement.style.left = `${x}px`;
        }
      });
    };

    if (containerRef.current && cameraRef.current && controlsRef.current) {
      updatePinsPosition();

      // Add event listeners for changes in viewport size or camera movement
      window.addEventListener("resize", updatePinsPosition);
      controlsRef.current.addEventListener("change", updatePinsPosition);

      return () => {
        // Cleanup: remove event listeners
        window.removeEventListener("resize", updatePinsPosition);
        controlsRef.current.removeEventListener("change", updatePinsPosition);
      };
    }
  }, [pins]);

  const renderPins = () => {
    return pins.map((pin, index) => {
      if (!pin.show) {
        return null;
      }

      // Get the position of the pin in world coordinates
      const worldPosition = new THREE.Vector3(
        pin.coordinates.x,
        pin.coordinates.y,
        pin.coordinates.z
      );

      // Project the world coordinates to screen coordinates
      const screenPosition = worldPosition.project(cameraRef.current);

      // Convert screen coordinates to CSS pixels
      const x = ((screenPosition.x + 1) / 2) * containerRef.current.clientWidth;
      const y =
        ((-screenPosition.y + 1) / 2) * containerRef.current.clientHeight;

      return (
        <button
          key={index}
          id={`pin-${index}`}
          className="absolute transition-all duration-75 ease-in-out ui-outside-scene" // Smooth movement
          style={{
            top: `${y}px`,
            left: `${x}px`,
            transform: "translate(-50%, -50%)", // Center the pin
          }}
          onClick={() => moveToAnnotation(pin.coordinates)} // Function to handle click
        >
          <div
            className="relative flex items-center justify-center w-8 h-8 bg-gray-700 bg-opacity-50 border border-gray-300 rounded-full shadow-lg hover:bg-gray-700 hover:shadow-xl hover:scale-110 transition-transform"
            title={pin.annotation}
          >
            <span className="text-white text-sm font-bold">{index + 1}</span>
          </div>
        </button>
      );
    });
  };

  // Mouse event listener for Distance Measurement mode
  useEffect(() => {
    let clickStartPos = { x: null, y: null };

    const onMouseDown = (event) => {
      if (event.button !== 0) return;
      clickStartPos = { x: event.clientX, y: event.clientY };
    };

    const onMouseUp = (event) => {
      if (event.button !== 0) return;
      const clickEndPos = { x: event.clientX, y: event.clientY };
      const dx = clickEndPos.x - clickStartPos.x;
      const dy = clickEndPos.y - clickStartPos.y;
      const distance = Math.sqrt(dx * dx + dy * dy);

      // Check if the click is on a UI element
      if (event.target.closest(".ui-outside-scene")) {
        return;
      }

      if (distance < 5) {
        if (isDistanceMeasuringMode && isDistanceMeasuringMode) {
          addPoint(event);
        }
      }
    };

    // Attach event listeners
    window.addEventListener("mousedown", onMouseDown);
    window.addEventListener("mouseup", onMouseUp);

    // Cleanup function to remove event listeners
    return () => {
      window.removeEventListener("mousedown", onMouseDown);
      window.removeEventListener("mouseup", onMouseUp);
    };
  }, [addPoint, isDistanceMeasuringMode]);

  const handleOpacityChange = (index, opacity) => {
    if (
      sceneRef.current &&
      modelRef.current &&
      modelRef.current.children[index]
    ) {
      modelRef.current.children[index].material.opacity = opacity;
      modelRef.current.children[index].material.needsUpdate = true;
    }
  };

  const handleVisibilityChange = (index, value) => {
    if (
      sceneRef.current &&
      modelRef.current &&
      modelRef.current.children[index]
    ) {
      modelRef.current.children[index].visible = value;
    }
  };

  const handleColorChange = (index, color) => {
    if (
      sceneRef.current &&
      modelRef.current &&
      modelRef.current.children[index]
    ) {
      modelRef.current.children[index].material.color.set(color);
    }
  };

  const handleColorSave = (index, color) => {
    const modelDbRef = ref(database, `cases/${id}/items/modelInfos/${index}`);
    update(modelDbRef, { color });

    // Redux store'da güncelleme
    const updatedModelInfos = [...thisCase.items.modelInfos];
    updatedModelInfos[index] = {
      ...updatedModelInfos[index],
      color,
    };

    dispatch(
      updateModelInfos({
        caseId: id,
        modelInfos: updatedModelInfos,
      })
    );
  };

  const handleCancelAnnotation = () => {
    setIsAnnotationModalOpen(false);
    setTempAnnotationDetails(null);
  };

  const handleCheckboxChange = (checked, id) => {
    setPins((prevPins) => {
      return prevPins.map((pin) => {
        if (pin.id === id) {
          return { ...pin, show: checked };
        }
        return pin;
      });
    });
  };

  const handleMoveToStructure = (index) => {
    const thisModel = modelRef.current.children[index];

    // Compute the bounding box for the model
    thisModel.geometry.computeBoundingBox();
    const boundingBox = thisModel.geometry.boundingBox;

    // Calculate the center of the bounding box
    const thisModelCenter = new THREE.Vector3();
    boundingBox.getCenter(thisModelCenter);
    thisModel.localToWorld(thisModelCenter); // Convert local center to world coordinates

    // Call the `moveToAnnotation` function with the center as the target position
    moveToAnnotation(thisModelCenter);
  };

  const clearDrawPoints = useCallback(() => {
    // Clear the draw points
    setDrawPoints([]);
    // Remove old line if exists
    if (sceneRef.current.userData.currentDrawingLine) {
      sceneRef.current.remove(sceneRef.current.userData.currentDrawingLine);
      sceneRef.current.userData.currentDrawingLine.geometry.dispose();
      sceneRef.current.userData.currentDrawingLine.material.dispose();
      sceneRef.current.userData.currentDrawingLine = null;
    }
  }, [sceneRef, setDrawPoints]);

  const calculateAndShowDistance = (points) => {
    let total = 0;
    for (let i = 0; i < points.length - 1; i++) {
      const pointA = points[i].position;
      const pointB = points[i + 1].position;
      const distance = pointA.distanceTo(pointB);
      total += distance;
    }
    setTotalDistance(total.toFixed(2));
  };

  const createPin = (position) => {
    if (!sceneRef.current) {
      setTimeout(() => {
        createPin(position);
      }, 1000);
      return null;
    }

    const geometry = new THREE.SphereGeometry(1, 32, 32);
    const material = new THREE.MeshBasicMaterial({ color: 0xff0000 });
    const pin = new THREE.Mesh(geometry, material);

    pin.position.set(position.x, position.y, position.z);
    sceneRef.current.add(pin);

    return pin; // Return the pin if creation was successful
  };

  // FullScreen event listener
  useEffect(() => {
    const handleFullScreenChange = () => {
      const isNowFullscreen = !!document.fullscreenElement;
      setIsFullscreen(isNowFullscreen);
    };

    document.addEventListener("fullscreenchange", handleFullScreenChange);

    return () => {
      document.removeEventListener("fullscreenchange", handleFullScreenChange);
    };
  }, [clearMeasurements, clearDrawPoints]);

  const toggleFullScreen = () => {
    if (!document.fullscreenElement) {
      containerRef.current.requestFullscreen().catch((err) => {
        console.error(
          `Error attempting to enable full-screen mode: ${err.message} (${err.name})`
        );
      });
    } else if (document.exitFullscreen) {
      document.exitFullscreen();
    }
  };

  const toggleAnnotationMode = () => {
    if (!isAnnotationMode) {
      setIsDistanceMeasuringMode(false);
      setIsScalpelMode(false);
      setShowOptions(false);
      clearDrawPoints();
      clearMeasurements();
      setIsDrillMode(false);
      setIsAnnotationMode(true);
      setActiveTab("annotations");
    } else {
      setIsAnnotationMode(false);
    }
  };

  const toggleDistanceMeasuringMode = () => {
    if (!isDistanceMeasuringMode) {
      setIsAnnotationMode(false);
      setIsScalpelMode(false);
      setShowOptions(false);
      clearDrawPoints();
      setIsDrillMode(false);
      setIsDistanceMeasuringMode(true);
      setActiveTab("measurements");
    } else {
      setIsDistanceMeasuringMode(false);
      clearMeasurements();
    }
  };

  const toggleScalpelMode = () => {
    if (!isScalpelMode) {
      setIsAnnotationMode(false);
      setIsDistanceMeasuringMode(false);
      clearMeasurements();
      setIsDrillMode(false);
      setIsScalpelMode(true);
      setActiveTab("models");
      setShowOptions(true);
      setDrawPoints([]);
    } else {
      setIsScalpelMode(false);
      setShowOptions(false);
      clearDrawPoints();
    }
  };

  const toggleDrillMode = () => {
    if (!isDrillMode) {
      setIsAnnotationMode(false);
      setIsDistanceMeasuringMode(false);
      setIsScalpelMode(false);
      setShowOptions(false);
      clearDrawPoints();
      clearMeasurements();
      setIsDrillMode(true);
      setActiveTab("models");
    } else {
      setIsDrillMode(false);
      clearDrawPoints();
    }
  };

  const resetCamera = () => {
    if (!initialCameraPosition || !initialCameraTarget) return;

    const initialPosition = initialCameraPosition;
    const initialTarget = initialCameraTarget;

    // Reset camera position
    cameraRef.current.position.set(
      initialPosition.x,
      initialPosition.y,
      initialPosition.z
    );
    controlsRef.current.target.set(
      initialTarget.x,
      initialTarget.y,
      initialTarget.z
    );

    // Update controls
    controlsRef.current.update();
  };

  function modifyGeometryAndAddToScene() {
    const modelGroup = modelRef.current;
    const scene = sceneRef.current;

    if (!modelGroup || !scene) {
      console.error("Model or scene not found.");
      return;
    }

    if (!drawPoints || drawPoints.length < 3) {
      console.error(
        "Invalid drawPoints. Ensure there are at least 3 points to form a shape."
      );
      return;
    }

    const points = drawPoints[0].equals(drawPoints[drawPoints.length - 1])
      ? drawPoints
      : [...drawPoints, drawPoints[0]];

    selectedModels.forEach((modelIndex) => {
      const model = modelGroup.children[modelIndex];
      const geometry = model.geometry;

      const newGeometry = cutGeometryWithShape(geometry, points, cutHeight);

      if (!newGeometry) {
        console.error("Failed to create new geometry.");
        return;
      }

      const newMaterial = new THREE.MeshStandardMaterial({
        color: model.material.color,
        transparent: true,
        opacity: 1,
        clippingPlanes: [],
      });

      const newMesh = new THREE.Mesh(newGeometry, newMaterial);

      newMesh.position.copy(model.position);
      newMesh.rotation.copy(model.rotation);
      newMesh.scale.copy(model.scale);
      newMesh.name = model.name;

      modelGroup.remove(model);
      modelGroup.children.splice(modelIndex, 0, newMesh);
    });

    setDrawPoints([]);
    if (sceneRef.current.userData.currentDrawingLine) {
      sceneRef.current.remove(sceneRef.current.userData.currentDrawingLine);
      sceneRef.current.userData.currentDrawingLine.geometry.dispose();
      sceneRef.current.userData.currentDrawingLine.material.dispose();
      sceneRef.current.userData.currentDrawingLine = null;
    }

    setProcessing(false);
  }

  function cutGeometryWithShape(geometry, basePoints, height) {
    const normal = getCameraNormalVector();
    const verticesWithinField = getVerticesWithinField(
      geometry,
      basePoints,
      normal,
      height
    );
    const newGeometry = removeTrianglesContainingVertices(
      geometry,
      verticesWithinField
    );
    return newGeometry;
  }

  function getCameraNormalVector() {
    const cameraDirection = new THREE.Vector3();
    cameraRef.current.getWorldDirection(cameraDirection);
    return cameraDirection.normalize();
  }

  function normalizeVector(vector) {
    return vector.normalize();
  }

  function projectBaseToVertexZ(basePoints, vertexPoint, normal, height) {
    normal = normalizeVector(normal.clone());
    const d = vertexPoint.dot(normal);
    const projectedPoints = basePoints.map((point) => {
      const pointVector = new THREE.Vector3(point.x, point.y, point.z).add(
        normal.clone().multiplyScalar(height)
      );
      const projection = pointVector.add(
        normal.clone().multiplyScalar(d - pointVector.dot(normal))
      );
      return projection;
    });
    return projectedPoints;
  }

  function pointInPolygon2D(px, py, poly) {
    let inside = false;
    for (let i = 0, j = poly.length - 1; i < poly.length; j = i++) {
      const xi = poly[i].x,
        yi = poly[i].y;
      const xj = poly[j].x,
        yj = poly[j].y;
      const intersect =
        // eslint-disable-next-line
        yi > py !== yj > py && px < ((xj - xi) * (py - yi)) / (yj - yi) + xi;
      if (intersect) inside = !inside;
    }
    return inside;
  }

  function getVerticesWithinField(geometry, basePoints, normal, height) {
    const verticesWithinField = [];
    const vertices = getVerticesFromBufferGeometry(geometry);

    vertices.forEach((vertex, index) => {
      const projectedBase = projectBaseToVertexZ(
        basePoints,
        vertex,
        normal,
        height
      );
      const projectedBase2D = projectedBase.map(
        (p) => new THREE.Vector2(p.x, p.y)
      );
      if (pointInPolygon2D(vertex.x, vertex.y, projectedBase2D)) {
        verticesWithinField.push(index);
      }
    });

    return verticesWithinField;
  }

  function getVerticesFromBufferGeometry(geometry) {
    const positionAttribute = geometry.attributes.position;
    const vertices = [];

    for (let i = 0; i < positionAttribute.count; i++) {
      const vertex = new THREE.Vector3();
      vertex.fromBufferAttribute(positionAttribute, i);
      vertices.push(vertex);
    }

    return vertices;
  }

  function removeTrianglesContainingVertices(geometry, vertexIndices) {
    const position = geometry.attributes.position.array;
    const normalAttribute = geometry.attributes.normal.array;
    const newVertices = [];
    const newNormals = [];
    const faceIndicesToRemove = new Set(vertexIndices);

    for (let i = 0; i < position.length; i += 9) {
      const a = i / 3;
      const b = a + 1;
      const c = a + 2;

      if (
        !faceIndicesToRemove.has(a) &&
        !faceIndicesToRemove.has(b) &&
        !faceIndicesToRemove.has(c)
      ) {
        newVertices.push(
          position[i],
          position[i + 1],
          position[i + 2],
          position[i + 3],
          position[i + 4],
          position[i + 5],
          position[i + 6],
          position[i + 7],
          position[i + 8]
        );

        newNormals.push(
          normalAttribute[i],
          normalAttribute[i + 1],
          normalAttribute[i + 2],
          normalAttribute[i + 3],
          normalAttribute[i + 4],
          normalAttribute[i + 5],
          normalAttribute[i + 6],
          normalAttribute[i + 7],
          normalAttribute[i + 8]
        );
      }
    }

    const newGeometry = new THREE.BufferGeometry();
    const newPositionAttribute = new THREE.Float32BufferAttribute(
      newVertices,
      3
    );
    const newNormalAttribute = new THREE.Float32BufferAttribute(newNormals, 3);
    newGeometry.setAttribute("position", newPositionAttribute);
    newGeometry.setAttribute("normal", newNormalAttribute);

    return newGeometry;
  }

  return (
    <div className="relative">
      <div className="relative h-[calc(100vh-128px)] overflow-hidden bg-gray-50">
        <div className="h-screen w-screen z-0" ref={containerRef}>
          {containerRef.current && cameraRef.current && renderPins()}
          {/* Tabs for sidebar navigation */}
          {isSidebarOpen && (
            <div className="ui-outside-scene">
              <Tabs
                activeTab={activeTab}
                onTabClick={setActiveTab}
                setIsSidebarOpen={setIsSidebarOpen}
              />
            </div>
          )}
          {/* Mini Button to Open Sidebar */}
          {!isSidebarOpen && (
            <button
              onClick={() => setIsSidebarOpen(true)}
              className="absolute top-8 right-0 transform -translate-y-1/2 bg-gray-800 text-white p-2 rounded-l ui-outside-scene"
            >
              <ArrowBackIosNewRoundedIcon />
            </button>
          )}
          {/* Conditionally render the appropriate sidebar */}
          {!isModelLoading && isSidebarOpen && (
            <div className="ui-outside-scene">
              {activeTab === "models" && (
                <ModelsSidebar
                  modelsRefs={modelRef.current.children}
                  onOpacityChange={handleOpacityChange}
                  onVisibilityChange={handleVisibilityChange}
                  onColorChange={handleColorChange}
                  onColorSave={handleColorSave}
                  onMoveToStructure={handleMoveToStructure}
                />
              )}
              {activeTab === "annotations" && (
                <AnnotationsSidebar
                  pins={pins}
                  onDelete={handleDeleteAnnotation}
                  onSave={handleSaveAnnotationText}
                  handleCheckboxChange={handleCheckboxChange}
                  itemId={itemId}
                  onSelect={(pin) => {
                    moveToAnnotation(
                      new THREE.Vector3(
                        pin.coordinates.x,
                        pin.coordinates.y,
                        pin.coordinates.z
                      )
                    );
                  }}
                />
              )}
              {activeTab === "measurements" && (
                <MeasurementsSidebar
                  totalDistance={totalDistance}
                  measurements={savedMeasurements}
                  onDelete={handleDeleteMeasurement}
                  onSave={handleSaveMeasurementText}
                  handleCheckboxChange={handleMeasurementShowAndHide}
                />
              )}
            </div>
          )}
          {/* Controls Container */}
          <div className="absolute bottom-2 md:bottom-32 left-0 w-full p-2 text-blue-500 z-10 text-sm ui-outside-scene">
            <div
              className={`flex items-center overflow-x-auto flex-nowrap space-x-4 ${
                isSidebarOpen ? "w-3/5" : "w-full"
              }`}
            >
              {/* Independent Controls */}
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  toggleAnnotationMode();
                }}
                className={`py-1 px-2 rounded whitespace-nowrap ${
                  isAnnotationMode
                    ? "bg-gray-700 text-green-700"
                    : "hover:bg-gray-700"
                }`}
              >
                Annotate
              </button>

              {/* Measure Distance Mode Button */}
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  toggleDistanceMeasuringMode();
                }}
                className={`py-1 px-2 rounded whitespace-nowrap ${
                  isDistanceMeasuringMode
                    ? "bg-gray-700 text-green-700"
                    : "hover:bg-gray-700"
                }`}
              >
                Measure
              </button>

              {/* Scalpel Mode Button */}
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  toggleScalpelMode();
                }}
                className={`py-1 px-2 rounded whitespace-nowrap ${
                  isScalpelMode
                    ? "bg-gray-700 text-green-700"
                    : "hover:bg-gray-700"
                }`}
              >
                Scalpel
              </button>
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  toggleDrillMode();
                }}
                className={`py-1 px-2 rounded whitespace-nowrap ${
                  isDrillMode
                    ? "bg-gray-700 text-green-700"
                    : "hover:bg-gray-700"
                }`}
              >
                Drill
              </button>
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  resetCamera();
                }}
                className="py-1 px-2 hover:bg-gray-700 rounded whitespace-nowrap"
              >
                Reset Camera
              </button>
              {!isMobile && (
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    toggleFullScreen();
                  }}
                  className="py-1 px-2 hover:bg-gray-700 rounded whitespace-nowrap"
                >
                  {isFullscreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
                </button>
              )}
            </div>

            {/* Message Boxes */}
            {isDistanceMeasuringMode && (
              <div className="absolute bottom-12 left-4 w-auto flex flex-col items-center z-50">
                <div className="flex flex-col items-center space-y-2 p-3 bg-white rounded-lg shadow-lg border border-gray-200">
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      clearMeasurements();
                    }}
                    className="w-full py-2 px-3 text-sm font-medium md:text-xs md:font-normal text-white bg-red-500 rounded hover:bg-red-600"
                  >
                    Clear Points
                  </button>
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      closePolygon();
                    }}
                    className="w-full py-2 px-3 text-sm font-medium md:text-xs md:font-normal text-white bg-gray-500 rounded hover:bg-gray-600"
                  >
                    Close Polygon
                  </button>
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      setIsMeasurementModalOpen(true);
                    }}
                    disabled={pathPoints.length < 2}
                    className={`w-full py-2 px-3 text-sm font-medium md:text-xs md:font-normal text-white rounded ${
                      pathPoints.length > 1
                        ? "bg-green-600 hover:bg-green-700 cursor-pointer"
                        : "bg-gray-400 cursor-not-allowed"
                    }`}
                  >
                    Save
                  </button>
                </div>
              </div>
            )}

            {isScalpelMode && (
              <div className="absolute bottom-12 left-4 w-auto md:w-1/4 flex flex-col items-start z-50">
                <div className="flex flex-col items-start space-y-3 p-3 bg-white rounded-lg shadow-lg border border-gray-200">
                  {showOptions && (
                    <>
                      <input
                        type="number"
                        value={cutHeight}
                        onChange={(e) =>
                          setCutHeight(parseFloat(e.target.value))
                        }
                        className="py-1 px-2 w-full bg-white border border-gray-300 rounded text-sm"
                        placeholder="Height"
                      />

                      {/* Scrollable list of all items, with only 3 visible */}
                      <div
                        className={`flex flex-col space-y-2 overflow-y-auto ${
                          isMobile ? "max-h-28" : "max-h-36"
                        }`}
                      >
                        {modelRef.current.children.map((model, index) => (
                          <label
                            key={model.name}
                            className="flex items-center space-x-2 w-full"
                          >
                            <input
                              type="checkbox"
                              checked={selectedModels.includes(index)}
                              onChange={() => {
                                setSelectedModels((prevSelectedModels) =>
                                  prevSelectedModels.includes(index)
                                    ? prevSelectedModels.filter(
                                        (i) => i !== index
                                      )
                                    : [...prevSelectedModels, index]
                                );
                              }}
                            />
                            <span className="text-sm md:text-xs text-gray-700">
                              {model.name}
                            </span>
                          </label>
                        ))}
                      </div>

                      {/* Buttons aligned next to each other */}
                      <div className="flex w-full justify-between items-center space-x-2">
                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                            setProcessing(true);
                            setTimeout(() => {
                              modifyGeometryAndAddToScene();
                            }, 100);
                          }}
                          className="flex-1 py-2 px-3 text-sm md:text-xs font-medium text-white bg-blue-500 rounded-lg hover:bg-blue-600"
                        >
                          Cut
                        </button>
                        <button
                          onClick={() => setShowOptions(!showOptions)}
                          className="py-2 px-3 text-sm md:text-xs text-gray-700 hover:text-gray-900 border border-gray-300 rounded-md"
                        >
                          Hide
                        </button>
                      </div>
                    </>
                  )}

                  {/* Show button when options are hidden */}
                  {!showOptions && (
                    <button
                      onClick={() => setShowOptions(true)}
                      className="self-end py-2 px-3 text-sm md:text-xs text-gray-700 hover:text-gray-900 border border-gray-300 rounded-md"
                    >
                      Show
                    </button>
                  )}
                </div>
              </div>
            )}

            {isDrillMode && (
              <div className="absolute bottom-12 left-4 w-auto md:w-1/4 flex flex-col items-center z-50">
                <div className="flex flex-col items-center space-y-2 p-2 rounded bg-white shadow-md">
                  <input
                    type="range"
                    min="0.1"
                    max="5"
                    step="0.1"
                    value={brushSize}
                    onChange={(e) => setBrushSize(parseFloat(e.target.value))}
                    className="w-full mb-2"
                  />
                  <label className="text-gray-700 text-sm md:text-xs">
                    Brush: {brushSize}
                  </label>
                </div>
              </div>
            )}

            {/* Annotation Modal */}
            {isAnnotationModalOpen && (
              <div className="ui-outside-scene">
                <AnnotationModal
                  isOpen={isAnnotationModalOpen}
                  onSave={handleSaveAnnotation}
                  onCancel={handleCancelAnnotation}
                  initialPosition={tempAnnotationDetails}
                  itemId={itemId}
                />
              </div>
            )}
            {/* Measurement Modal */}
            {isMeasurementModalOpen && (
              <div className="ui-outside-scene">
                <MeasurementModal
                  isOpen={isMeasurementModalOpen}
                  onSave={handleSaveMeasurements}
                  onCancel={() => setIsMeasurementModalOpen(false)}
                />
              </div>
            )}
            {processing && (
              <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50 z-50">
                <div className="text-white text-lg font-bold">
                  Processing...
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModelViewer;
