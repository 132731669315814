import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { auth, database } from "../../firebase";
import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
} from "firebase/auth";
import { ref, set } from "firebase/database";
import useIsMobile from "../../hooks/useIsMobile";
import SignupInputFields from "./SignupInputFields";
import getErrorMessage from "../../utils/errorUtils";

const SignUpForm = () => {
  const navigate = useNavigate();
  const isMobile = useIsMobile();

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    department: "",
    institutionName: "",
    number: "",
    email: "",
    password: "",
    confirmPassword: "",
  });
  const [error, setError] = useState("");

  const handleChange = (field) => (value) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    const {
      firstName,
      lastName,
      department,
      institutionName,
      number,
      email,
      password,
      confirmPassword,
    } = formData;

    if (!firstName || !lastName || !email || !password || !confirmPassword) {
      setError("Please fill in all required fields.");
      return;
    }

    if (password !== confirmPassword) {
      setError("Passwords do not match.");
      return;
    }

    if (password.length < 6) {
      setError("Password should be at least 6 characters.");
      return;
    }

    setError("");

    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );

      await sendEmailVerification(userCredential.user);

      const userRef = ref(database, `users/${userCredential.user.uid}`);
      await set(userRef, {
        id: userCredential.user.uid,
        firstName,
        lastName,
        department,
        institutionName,
        number,
        email,
      });

      navigate("/login");
    } catch (error) {
      setError(getErrorMessage(error.message));
    }
  };

  return (
    <div className={isMobile ? "px-4 pt-16 overflow-auto mb-8" : "flex h-screen"}>
      <div
        className={
          isMobile
            ? "p-8 rounded-xl shadow-md shadow-blue-100 bg-blue-100/50 w-full max-w-lg mx-auto"
            : "flex-1 max-w-xl mx-auto p-6 rounded-xl shadow-md shadow-blue-100 bg-blue-100/50 h-full overflow-y-auto"
        }
      >
        <h2 className="text-2xl font-bold mb-6 pb-4 border-b-2 text-center border-blue-500 text-blue-500">
          Register
        </h2>
        <form onSubmit={onSubmit}>
          <SignupInputFields formData={formData} handleChange={handleChange} />
          <div className="py-2">
            <button
              type="submit"
              className="w-full bg-blue-500 hover:bg-blue-600 hover:text-green-400 text-slate-50 py-2 px-4 rounded-md font-bold"
            >
              Submit
            </button>
          </div>
          <p className="text-sm text-gray-500/75">
            (*) Required fields
          </p>
          {error && <p className="text-red-600 mt-2">{error}</p>}
          <p className="mt-2 text-sm text-gray-500/75 text-center">
            Already have an account?
            <NavLink
              to="/login"
              className="ml-1 text-blue-500 hover:underline underline-offset-2"
            >
              Please Login
            </NavLink>
            .
          </p>
        </form>
      </div>
      {!isMobile && (
        <div className="flex-1">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/d-viewer-de717.appspot.com/o/source%2Fhello.jpg?alt=media&token=1fa16b85-b32e-4711-89e4-0151e06797ba"
            alt="3D Model"
            className="w-full h-screen object-cover"
          />
        </div>
      )}
    </div>
  );  
};

export default SignUpForm;
