import React from "react";

const CaseSearchBar = ({
  searchQuery,
  setSearchQuery,
  onAddCase,
  toggleDeleteMode,
  isDeleteModeActive,
  isMobile,
}) => {
  return (
    <div className={isMobile ? "flex flex-col space-y-2 mb-4" : "flex justify-between items-center mb-4"}>
      <input
        type="text"
        placeholder="Search..."
        className={
          isMobile
            ? "px-2 py-2 border border-gray-300 rounded text-sm"
            : "px-4 py-2 border border-gray-300 rounded" 
        }
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
      />

      <div className={isMobile ? "flex space-x-2 pt-1" : "flex space-x-4"}>
        <button
          onClick={onAddCase}
          className={
            isMobile
              ? "px-3 py-1 bg-blue-500 text-white rounded text-sm hover:bg-blue-600"
              : "px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
          }
        >
          Add Case
        </button>
        <button
          onClick={toggleDeleteMode}
          className={
            isMobile
              ? `px-3 py-2 rounded text-sm ${
                  isDeleteModeActive
                    ? "bg-[#383d3a] text-white hover:bg-gray-800"
                    : "bg-red-500 text-white hover:bg-red-600"
                }`
              : `px-4 py-2 rounded ${
                  isDeleteModeActive
                    ? "bg-[#383d3a] text-white hover:bg-gray-800"
                    : "bg-red-500 text-white hover:bg-red-600"
                }`
          }
        >
          {isDeleteModeActive ? "Cancel" : "Delete"}
        </button>
      </div>
    </div>
  );
};

export default CaseSearchBar;
