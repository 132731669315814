import React, { useState } from "react";
import SquareFootIcon from "@mui/icons-material/SquareFoot";
import LabelIcon from "@mui/icons-material/Label";
import ViewInArIcon from "@mui/icons-material/ViewInAr";
import CloseIcon from "@mui/icons-material/Close";

const Tabs = ({ activeTab, onTabClick, setIsSidebarOpen }) => {
  // State for individual tooltips
  const [isModelTooltipVisible, setModelTooltipVisible] = useState(false);
  const [isAnnotationTooltipVisible, setAnnotationTooltipVisible] =
    useState(false);
  const [isMeasurementTooltipVisible, setMeasurementTooltipVisible] =
    useState(false);
  const [isCloseTooltipVisible, setCloseTooltipVisible] = useState(false);

  return (
    <div className="absolute right-0 top-0 h-full bg-gray-100 overflow-y-auto shadow-lg w-72 md:w-44 transition-all duration-300 ease-in-out">
      <div className="flex flex-row">
        {/* Models Tab */}
        <div className="relative">
          <button
            onClick={() => onTabClick("models")}
            onMouseEnter={() => setModelTooltipVisible(true)}
            onMouseLeave={() => setModelTooltipVisible(false)}
            className={`flex items-center justify-center py-2 px-4 md:w-10 transition-colors duration-300 ${
              activeTab === "models"
                ? "bg-blue-500 text-white"
                : "bg-gray-100 text-blue-500"
            }`}
          >
            <ViewInArIcon />
          </button>
          {/* Tooltip */}
          {isModelTooltipVisible && (
            <div className="absolute bg-gray-800 text-white text-xs rounded px-2 py-1 transition-opacity duration-200 z-50">
              Segmented Structures
            </div>
          )}
        </div>

        {/* Annotations Tab */}
        <div className="relative">
          <button
            onClick={() => onTabClick("annotations")}
            onMouseEnter={() => setAnnotationTooltipVisible(true)}
            onMouseLeave={() => setAnnotationTooltipVisible(false)}
            className={`flex items-center justify-center py-2 px-4 md:w-10 transition-colors duration-300 ${
              activeTab === "annotations"
                ? "bg-blue-500 text-white"
                : "bg-gray-100 text-blue-500"
            }`}
          >
            <LabelIcon />
          </button>
          {/* Tooltip */}
          {isAnnotationTooltipVisible && (
            <div className="absolute bg-gray-800 text-white text-xs rounded px-2 py-1 transition-opacity duration-200 z-50">
              Annotations
            </div>
          )}
        </div>

        {/* Measurements Tab */}
        <div className="relative">
          <button
            onClick={() => onTabClick("measurements")}
            onMouseEnter={() => setMeasurementTooltipVisible(true)}
            onMouseLeave={() => setMeasurementTooltipVisible(false)}
            className={`flex items-center justify-center py-2 px-4 md:w-10 transition-colors duration-300 ${
              activeTab === "measurements"
                ? "bg-blue-500 text-white"
                : "bg-gray-100 text-blue-500"
            }`}
          >
            <SquareFootIcon />
          </button>
          {/* Tooltip */}
          {isMeasurementTooltipVisible && (
            <div className="absolute bg-gray-800 text-white text-xs rounded px-2 py-1 transition-opacity duration-200 z-50">
              Measurements
            </div>
          )}
        </div>

        {/* Close Button */}
        <div className="relative">
          <button
            onClick={() => setIsSidebarOpen(false)}
            onMouseEnter={() => setCloseTooltipVisible(true)}
            onMouseLeave={() => setCloseTooltipVisible(false)}
            className="flex items-center justify-center h-full py-1 px-2 transition-colors md:w-8 duration-300 bg-gray-800 text-white"
          >
            <CloseIcon />
          </button>
          {/* Tooltip */}
          {isCloseTooltipVisible && (
            <div className="absolute bg-gray-800 text-white text-xs rounded px-2 py-1 transition-opacity duration-200 z-50">
              Close
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Tabs;
