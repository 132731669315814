import Navbar from "../components/common/Navbar";
import CaseListPageView from "../components/caselist/CaseListPageView";

const CaseList = () => {
  return (
    <>
      <Navbar />
      <CaseListPageView />
    </>
  );
};

export default CaseList;
