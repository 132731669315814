import React from "react";

const ForgotPasswordForm = ({
  handleResetPassword,
  resetEmailSent,
  resetEmailError,
  goBackToLogin,
}) => {
  return (
    <div>
      <form className="space-y-4" onSubmit={handleResetPassword}>
        <div className="border-b-2 pb-6 border-blue-500">
          <label
            htmlFor="resetEmail"
            className="block text-gray-500/75 text-sm font-semibold mb-1"
          >
            Email
          </label>
          <input
            id="resetEmail"
            type="email"
            className="w-full px-3 py-2 mt-1 shadow bg-slate-50 hover:bg-slate-100 focus:bg-slate-100 rounded-md focus:outline-none focus:shadow-sky-300 text-gray-600"
            placeholder="Enter your email"
          />
        </div>

        <div className="py-2">
          <button
            type="submit"
            className="w-full bg-blue-500 hover:bg-blue-600 hover:text-green-400 text-slate-50 py-2 px-4 rounded-md font-bold"
          >
            Send Email
          </button>
        </div>

        {resetEmailSent && (
          <p className="text-green-600 mt-1">
            Reset password email sent. Please check your inbox.
          </p>
        )}
        {resetEmailError && (
          <p className="text-red-600 mt-2">{resetEmailError}</p>
        )}

        <p className="mt-2 text-sm text-gray-600 text-center">
          <button
            type="button"
            className="text-blue-500 hover:underline underline-offset-2"
            onClick={goBackToLogin}
          >
            Go to Login page.
          </button>
        </p>
      </form>
    </div>
  );
};

export default ForgotPasswordForm;
