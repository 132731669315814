import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { useSelector } from "react-redux";

import Signup from "./pages/SignUp";
import Login from "./pages/Login";
import ThreeDModel from "./pages/ThreeDModel";
import CaseList from "./pages/CaseList";
import CasesPage from "./pages/CasesPage";
import ProfilePage from "./pages/ProfilePage";
import AddOrEditCasePage from "./pages/AddOrEditCasePage";
import NotFound from "./pages/NotFound";

import Footer from "./components/common/Footer";

function App() {
  const isSignedIn = useSelector((state) => state.user.isSignedIn);
  return (
    <div className="flex flex-col min-h-screen">
      <Router>
        <div className="flex-grow">
          <Routes>
            <Route
              path="/"
              element={isSignedIn ? <CaseList /> : <Navigate to="/login" />}
            />
            <Route
              path="/home"
              element={isSignedIn ? <CaseList /> : <Navigate to="/login" />}
            />
            <Route
              path="/signup"
              element={isSignedIn ? <Navigate to="/caselist" /> : <Signup />}
            />
            <Route
              path="/login"
              element={isSignedIn ? <Navigate to="/caselist" /> : <Login />}
            />
            <Route path="/case/:id" element={<CasesPage />} />
            <Route
              path="/caselist"
              element={isSignedIn ? <CaseList /> : <Navigate to="/login" />}
            />
            <Route
              path="/profilepage"
              element={isSignedIn ? <ProfilePage /> : <Navigate to="/login" />}
            />
            <Route
              path="/addoreditcasepage"
              element={isSignedIn ? <AddOrEditCasePage /> : <Navigate to="/login" />}
            />
            <Route path="/model/:id" element={<ThreeDModel />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </div>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
