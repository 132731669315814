import { useState } from "react";
import { database } from "../../firebase";
import { ref, set, push, get, update } from "firebase/database";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import useIsMobile from "../../hooks/useIsMobile";
import { Roles } from "../../constants/roles";
import { Statuses } from "../../constants/statuses";
import parseDate from "../../utils/dateUtils";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const AddOrEditCasePageView = () => {
  const user = useSelector((state) => state.user.currentUser);
  const selectedCase = useSelector((state) => state.cases.selectedCase);
  const navigate = useNavigate();
  const isMobile = useIsMobile();

  const [title, setTitle] = useState(selectedCase?.title || "");
  const [anatomy, setAnatomy] = useState(selectedCase?.anatomy || "");
  const [requester, setRequester] = useState(selectedCase?.requester || "");
  const [dueDate, setDueDate] = useState(
    selectedCase ? parseDate(selectedCase.dueDate) : null
  );
  const [creationDate, setCreationDate] = useState(
    selectedCase ? parseDate(selectedCase.creationDate) : new Date()
  );
  const [expirationDate, setExpirationDate] = useState(
    selectedCase ? parseDate(selectedCase.expirationDate) : null
  );
  const [error, setError] = useState(null);

  const onSubmit = async (e) => {
    e.preventDefault();

    const updatedCreator = e.target.creator.value;

    if (
      [
        { value: title, name: "Title" },
        { value: anatomy, name: "Anatomy" },
        { value: requester, name: "Requester" },
        { value: dueDate, name: "Due Date" },
        { value: expirationDate, name: "Expiration Date" },
        { value: creationDate, name: "Creation Date" },
        { value: updatedCreator, name: "Creator" },
      ].some((field) => !field.value)
    ) {
      setError("Please fill in all required fields.");
      return;
    }

    if (!user || !user.id) {
      setError("User is not loaded correctly.");
      return;
    }

    try {
      if (selectedCase) {
        const updatedCaseData = {
          ...selectedCase,
          title,
          anatomy,
          requester,
          dueDate: dueDate.toDateString(),
          creationDate: creationDate.toDateString(),
          expirationDate: expirationDate.toDateString(),
          creator: updatedCreator,
        };

        await set(ref(database, `cases/${selectedCase.id}`), updatedCaseData);
        navigate("/caselist");
      } else {
        const newCaseKey = push(ref(database, `cases`)).key;

        const newCaseData = {
          id: newCaseKey,
          title,
          anatomy,
          requester,
          dueDate: dueDate.toDateString(),
          creationDate: creationDate.toDateString(),
          expirationDate: expirationDate.toDateString(),
          creator: updatedCreator,
          status: Statuses.UPLOAD_DICOM,
          users: {
            [user.id]: {
              role: Roles.CREATOR,
              name: `${user.firstName} ${user.lastName}`,
            },
          },
        };

        await set(ref(database, `cases/${newCaseKey}`), newCaseData);

        const userRef = ref(database, `users/${user.id}`);
        const snapshot = await get(userRef);

        if (!snapshot.exists()) {
          setError("User not found.");
          return;
        }

        const userData = snapshot.val();
        const existingCases = userData.cases || [];
        const updatedCases = [...existingCases, newCaseKey];
        await update(userRef, { cases: updatedCases });

        navigate("/caselist");
      }
    } catch (err) {
      console.error("Error saving case:", err);
      setError("Error saving case.");
    }
  };

  return (
    <div
      className={`px-4 pt-16 pb-16 relative ${
        isMobile
          ? "overflow-auto"
          : "h-screen flex items-center justify-center bg-gray-100"
      }`}
    >
      <button
        onClick={() => navigate(-1)}
        className="absolute top-4 left-4 text-blue-400 px-4 py-2 rounded mb-4 transition-colors duration-300 z-10 hover:bg-blue-500"
      >
        <ArrowBackIcon />
      </button>
      <div className="p-8 rounded-xl shadow-md shadow-blue-100 bg-white w-full max-w-lg mx-auto">
        <h2 className="text-2xl font-bold mb-6 pb-6 border-b-2 text-center border-blue-500 text-blue-500">
          {selectedCase ? "Edit Case" : "Add New Case"}
        </h2>
        {error && <p className="text-red-500 text-center mb-4">{error}</p>}
        <form
          className="space-y-4"
          onSubmit={(e) => {
            setError(null);
            onSubmit(e);
          }}
        >
          <div>
            <label
              htmlFor="title"
              className="block text-gray-700 text-sm font-semibold mb-1"
            >
              Title
            </label>
            <input
              id="title"
              type="text"
              className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Enter title"
              required
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </div>
          <div>
            <label
              htmlFor="anatomy"
              className="block text-gray-700 text-sm font-semibold mb-1"
            >
              Anatomy
            </label>
            <input
              id="anatomy"
              type="text"
              className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Enter anatomy"
              required
              value={anatomy}
              onChange={(e) => setAnatomy(e.target.value)}
            />
          </div>
          <div>
            <label
              htmlFor="creator"
              className="block text-gray-700 text-sm font-semibold mb-1"
            >
              Creator
            </label>
            <input
              id="creator"
              type="text"
              className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Enter creator"
              defaultValue={
                selectedCase
                  ? selectedCase.creator
                  : user
                  ? `${user.firstName} ${user.lastName}`
                  : ""
              }
              required
            />
          </div>
          <div>
            <label
              htmlFor="requester"
              className="block text-gray-700 text-sm font-semibold mb-1"
            >
              Requester
            </label>
            <input
              id="requester"
              type="text"
              className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Enter requester"
              required
              value={requester}
              onChange={(e) => setRequester(e.target.value)}
            />
          </div>
          <div>
            <label
              htmlFor="creationDate"
              className="block text-gray-700 text-sm font-semibold mb-1"
            >
              Creation Date
            </label>
            <DatePicker
              selected={creationDate}
              onChange={(date) => setCreationDate(date)}
              dateFormat="dd/MM/yyyy"
              className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholderText="Select creation date"
              required
            />
          </div>
          <div>
            <label
              htmlFor="dueDate"
              className="block text-gray-700 text-sm font-semibold mb-1"
            >
              Due Date
            </label>
            <DatePicker
              selected={dueDate}
              onChange={(date) => setDueDate(date)}
              dateFormat="dd/MM/yyyy"
              className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholderText="Select due date"
              required
            />
          </div>
          <div>
            <label
              htmlFor="expirationDate"
              className="block text-gray-700 text-sm font-semibold mb-1"
            >
              Expiration Date
            </label>
            <DatePicker
              selected={expirationDate}
              onChange={(date) => setExpirationDate(date)}
              dateFormat="dd/MM/yyyy"
              className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholderText="Select expiration date"
              required
            />
          </div>
          <button
            type="submit"
            className="w-full bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 focus:outline-none focus:bg-blue-600"
          >
            {selectedCase ? "Update Case" : "Submit"}
          </button>
        </form>
      </div>
    </div>
  );
};

export default AddOrEditCasePageView;
