import React from "react";
import { Link } from "react-router-dom";

const LoginFormInputFields = ({
  handleSignIn,
  isFetching,
  goForgotMyPassword,
  error,
  handleError,
}) => {
  return (
    <div>
      <form className="space-y-4" onSubmit={handleSignIn}>
        <div>
          <label
            htmlFor="email"
            className="block text-gray-500/75 text-sm font-semibold mb-1"
          >
            Email
          </label>
          <input
            id="email"
            type="email"
            className="w-full px-3 py-2 mt-1 shadow bg-slate-50 hover:bg-slate-100 focus:bg-slate-100 rounded-md focus:outline-none focus:shadow-sky-300 text-gray-600"
            placeholder="Enter your email"
          />
        </div>
        <div className="border-b-2 pb-6 border-blue-500">
          <label
            htmlFor="password"
            className="block text-gray-500/75 text-sm font-semibold mb-1"
          >
            Password
          </label>
          <input
            id="password"
            type="password"
            className="w-full px-3 py-2 mt-1 mb-4 shadow bg-slate-50 hover:bg-slate-100 focus:bg-slate-100 rounded-md focus:outline-none focus:shadow-sky-300 text-gray-600"
            placeholder="Enter your password"
          />
        </div>
        <div className="py-2">
          <button
            type="submit"
            className={`w-full bg-blue-500 hover:bg-blue-600 hover:text-green-400 text-slate-50 py-2 px-4 rounded-md font-bold ${
              isFetching ? "opacity-50 cursor-not-allowed" : ""
            }`}
            disabled={isFetching}
          >
            Sign In
          </button>
        </div>
      </form>
      <div className="flex justify-between items-center">
        <p className="mt-2 text-sm text-gray-600 text-center">
          <button
            className="text-blue-500 hover:underline underline-offset-2"
            onClick={goForgotMyPassword}
          >
            Forgot your password?
          </button>
        </p>
        <p className="mt-2 text-sm text-gray-500/75 text-center">
          Not a member?
          <br />
          <Link
            to="/signup"
            className="text-blue-500 hover:underline underline-offset-2"
          >
            Please Sign Up
          </Link>
        </p>
      </div>
      {error && <p className="text-red-600 mt-2">{handleError}</p>}
    </div>
  );
};

export default LoginFormInputFields;
