import useIsMobile from "../../hooks/useIsMobile";

const Footer = () => {
  const isMobile = useIsMobile();

  return (
    <footer
      className={`bg-gradient-to-r from-blue-400 via-blue-500 to-blue-600 mt-auto ${
        isMobile ? "py-2" : "py-6"
      }`}
    >
      <div className="container mx-auto text-center">
        <p className={`${isMobile ? "text-sm" : "text-lg"} text-slate-50`}>
          Copyright © 2024 Wellztech. All rights reserved.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
