const CaseInformation = ({ selectedCase, isMobile }) => {
    if (!selectedCase) {
      return <p>Loading case information...</p>;
    }
  
    return (
      <div className={isMobile ? "mb-4" : "mb-8"}>
        <h2 className="text-lg font-semibold mb-2">Case Information</h2>
        <div className={isMobile ? "flex flex-col space-y-2" : "grid grid-cols-2 gap-4"}>
          <div className={isMobile ? "" : "border-r border-gray-400 pr-4"}>
            <p className="text-gray-600">Name: {selectedCase.title}</p>
            <p className="text-gray-600">Anatomy: {selectedCase.anatomy}</p>
            <p className="text-gray-600">Creator: {selectedCase.creator}</p>
          </div>
          <div>
            <p className="text-gray-600">Creation Date: {selectedCase.creationDate}</p>
            <p className="text-gray-600">Expiry Date: {selectedCase.expirationDate}</p>
            <p className="text-gray-600">Due Date: {selectedCase.dueDate}</p>
          </div>
        </div>
      </div>
    );
  };
  
export default CaseInformation;
