import React, { useState } from "react";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import CloseIcon from "@mui/icons-material/Close";

const MeasurementsSidebar = ({
  totalDistance,
  measurements,
  onSave,
  onDelete,
  handleCheckboxChange,
}) => {
  const [editIndex, setEditIndex] = useState(null);
  const [editedText, setEditedText] = useState("");

  return (
    <div className="absolute right-0 top-10 w-72 md:w-44 h-full bg-gray-100 overflow-y-auto shadow-lg z-20">
      {/* Header */}
      <div className="bg-blue-500 text-white text-center h-12 flex items-center justify-center font-semibold">
        MEASUREMENTS
      </div>

      {/* Total Distance */}
      <div className="px-6 py-4">
        <div className="text-lg md:text-base font-semibold text-gray-800">
          Total Distance:{" "}
          <span className="text-2xl md:text-lg font-bold text-gray-900">
            {totalDistance} mm
          </span>
        </div>
      </div>

      {/* Measurements List */}
      <ul className="list-none m-0 p-0">
        {measurements.map((measurement, index) => (
          <li
            key={index}
            className="cursor-pointer p-4 hover:bg-blue-100 bg-gray-200 border-b-2 border-gray-300 transition-colors duration-150 ease-in-out flex justify-between items-center"
          >
            {/* Measurement Name */}
            <div className="flex items-center justify-start w-full">
              {editIndex === index ? (
                // Editable input for name
                <input
                  type="text"
                  value={editedText}
                  onChange={(e) => setEditedText(e.target.value)}
                  className="w-full border border-gray-300 rounded px-2 py-1 text-gray-800 focus:outline-none focus:ring focus:border-blue-500"
                />
              ) : (
                // Display measurement name
                <div>
                  <div className="font-medium md:font-normal md:text-sm text-gray-800">
                    {measurement.name}
                  </div>
                  <div className="text-xs md:font-light text-gray-600 truncate">
                    {measurement.totalDistance} mm
                  </div>
                </div>
              )}
            </div>

            {/* Buttons */}
            <div className="flex items-center">
              {/* Show/Hide Toggle */}
              <label
                className="inline-flex items-center cursor-pointer"
                onClick={(e) => e.stopPropagation()}
              >
                <input
                  type="checkbox"
                  value={measurement.show ? "true" : "false"}
                  className="sr-only peer"
                  checked={measurement.show}
                  onChange={(e) => {
                    handleCheckboxChange(e.target.checked, measurement.id);
                  }}
                />
                <div className="relative w-7 h-4 md:w-5 md:h-3 bg-gray-200 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full md:after:h-2 md:after:w-2 after:h-3 after:w-3 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
              </label>

              {/* Edit/Save Button */}
              <button
                onClick={() => {
                  if (editIndex === index) {
                    // Save edited name
                    onSave(editedText, measurement.id);
                    setEditIndex(null); // Exit edit mode
                  } else {
                    // Enter edit mode
                    setEditIndex(index);
                    setEditedText(measurement.name); // Set current name in state
                  }
                }}
                className="px-2 py-1 md:p-0 bg-blue-500 text-white font-semibold md:font-normal text-xs rounded hover:bg-blue-600 transition-colors duration-150 ease-in-out ml-2 md:h-8 md:w-8"
              >
                {editIndex === index ? <SaveIcon /> : <EditIcon />}
              </button>

              {/* Cancel Button */}
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  if (editIndex === index) {
                    // Cancel edit mode
                    setEditIndex(null);
                  } else {
                    // Delete logic
                    onDelete(index);
                  }
                }}
                className={`px-2 py-1 md:p-0 ${
                  editIndex === index
                    ? "bg-gray-500 hover:bg-gray-600"
                    : "bg-red-500 hover:bg-red-600"
                } text-white font-semibold md:font-normal text-xs rounded transition-colors duration-150 ease-in-out ml-2 md:h-8 md:w-8`}
              >
                {editIndex === index ? <CloseIcon /> : <DeleteOutlineIcon />}
              </button>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default MeasurementsSidebar;
