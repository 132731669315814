import React from "react";
import useIsMobile from "../../hooks/useIsMobile";

const UploadDICOMModal = ({
  progress,
  uploading,
  success,
  onClose,
  onUpload,
}) => {
  const isMobile = useIsMobile();

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div
        className={`bg-white p-8 rounded shadow-lg ${
          isMobile ? "w-full max-w-xs mx-4" : "w-1/2"
        }`}
      >
        <h2 className="text-xl font-semibold mb-4">Upload DICOM Files</h2>
        {uploading ? (
          <div className="mb-4">
            <p>Upload progress: {progress ? progress.toFixed(2) : 0}%</p>
            <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
              <div
                className="bg-blue-600 h-2.5 rounded-full"
                style={{ width: `${progress || 0}%` }}
              ></div>
            </div>
          </div>
        ) : (
          <div className="mb-4">
            <p>Ready to upload your DICOM file.</p>
          </div>
        )}
        <div className="flex justify-end space-x-4">
          <button
            onClick={onClose}
            className="bg-gray-500 text-white px-4 py-2 rounded"
            disabled={uploading}
          >
            Cancel
          </button>
          <button
            onClick={onUpload}
            className="bg-blue-500 text-white px-4 py-2 rounded"
            disabled={uploading}
          >
            Upload
          </button>
        </div>
        {success && (
          <div className="mt-4 text-green-500 text-center">
            Upload Successful!
          </div>
        )}
      </div>
    </div>
  );
};

export default UploadDICOMModal;
