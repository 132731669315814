import Navbar from "../components/common/Navbar";
import LoginForm from "../components/login/LoginForm";

const Login = () => {
  return (
    <>
      <Navbar />
      <LoginForm />
    </>
  );
};

export default Login;
