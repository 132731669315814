import Navbar from "../components/common/Navbar";
import CasesPageView from "../components/casedetail/CasesPageView";

const CasesPage = () => {
  return (
    <>
      <Navbar />
      <CasesPageView />
    </>
  );
};

export default CasesPage;
