const getErrorMessage = (errorMessage) => {
    if (errorMessage.includes("invalid-credential")) return "Your password or email is incorrect.";
    if (errorMessage.includes("network-request-failed")) return "Check your internet connection!";
    if (errorMessage.includes("missing-email")) return "Please enter your email.";
    if (errorMessage.includes("invalid-email")) return "Please enter your email.";
    if (errorMessage.includes("missing-password")) return "Please enter your password.";
    if (errorMessage.includes("auth/email-already-in-use")) return "This email is already in use.";
    return "Something went wrong!";
  };
  
  export default getErrorMessage;