import React from "react";
import { useDispatch } from "react-redux";
import { setSelectedCase } from "../../redux/casesRedux";
import { Statuses } from "../../constants/statuses";
import { Roles } from "../../constants/roles";

const MobileCaseTable = ({
  sortedCaseList,
  navigate,
  showDeleteButtons,
  openModal,
  user,
}) => {
  const dispatch = useDispatch();

  const handleCaseClick = (caseItem) => {
    dispatch(setSelectedCase(caseItem));
    navigate(`/case/${caseItem.id}`);
  };

  const isCreator = (caseItem) => {
    return Object.entries(caseItem.users || {}).some(
      ([userId, userInfo]) => userId === user?.id && userInfo.role === Roles.CREATOR
    );
  };

  return (
    <div>
      {sortedCaseList.map((caseItem) => (
        <div
          key={caseItem.id}
          className="mb-4 p-4 border border-gray-300 rounded-lg"
          onClick={() => handleCaseClick(caseItem)}
        >
          <table className="w-full">
            <tbody>
              {[
                { label: "Title", value: caseItem.title },
                { label: "Anatomy", value: caseItem.anatomy },
                { label: "Creator", value: caseItem.creator },
                { label: "Requester", value: caseItem.requester },
                {
                  label: "Shared Users",
                  value: caseItem.users
                    ? Object.values(caseItem.users)
                        .filter((user) => user.role !== Roles.CREATOR)
                        .map((user) => user.name)
                        .join(", ")
                    : "Not Shared",
                },
                { label: "Creation Date", value: caseItem.creationDate },
                { label: "Due Date", value: caseItem.dueDate },
                { label: "Expiration Date", value: caseItem.expirationDate },
                {
                  label: "Status",
                  value: (
                    <span
                      className={`px-2 py-1 rounded ${
                        caseItem.status === Statuses.IN_PROGRESS
                          ? "bg-yellow-200"
                          : caseItem.status === Statuses.READY
                          ? "bg-green-200"
                          : caseItem.status === Statuses.UPLOAD_DICOM
                          ? "bg-orange-200"
                          : ""
                      }`}
                    >
                      {caseItem.status}
                    </span>
                  ),
                },
              ].map(({ label, value }) => (
                <tr key={label}>
                  <td className="p-2 font-semibold break-words max-w-[150px]">
                    {label}
                  </td>
                  <td className="p-2 break-words max-w-[200px]">{value}</td>
                </tr>
              ))}
            </tbody>
          </table>
          {showDeleteButtons && isCreator(caseItem) && (
            <button
              onClick={(e) => {
                e.stopPropagation();
                openModal(caseItem.id);
              }}
              className="mt-2 px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600"
            >
              Delete
            </button>
          )}
        </div>
      ))}
    </div>
  );
};

export default MobileCaseTable;
