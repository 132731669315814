import { createSlice } from "@reduxjs/toolkit";

const casesSlice = createSlice({
  name: "cases",
  initialState: {
    caseList: [],
    selectedCase: null,
    isLoading: false,
    error: false,
  },
  reducers: {
    fetchCasesStart: (state) => {
      state.isLoading = true;
      state.error = false;
    },
    fetchCasesSuccess: (state, action) => {
      state.isLoading = false;
      state.caseList = action.payload;
      state.error = false;
    },
    fetchCasesFailure: (state) => {
      state.isLoading = false;
      state.error = true;
    },
    setSelectedCase: (state, action) => {
      state.selectedCase = action.payload;
    },
    updateModelInfos: (state, action) => {
      const { modelInfos } = action.payload;
      if (state.selectedCase) {
        if (!state.selectedCase.items) {
          state.selectedCase.items = {};
        }
        state.selectedCase.items.modelInfos = modelInfos;
      }
    },
    updateAnnotations: (state, action) => {
      const { actionType, annotationId, annotationData } = action.payload;

      if (!state.selectedCase || !state.selectedCase.items) return;

      if (!state.selectedCase.items.annotations) {
        state.selectedCase.items.annotations = {};
      }

      switch (actionType) {
        case "add":
          state.selectedCase.items.annotations[annotationId] = annotationData;
          break;
        case "update":
          if (state.selectedCase.items.annotations[annotationId]) {
            state.selectedCase.items.annotations[annotationId] = {
              ...state.selectedCase.items.annotations[annotationId],
              ...annotationData,
            };
          }
          break;
        case "delete":
          delete state.selectedCase.items.annotations[annotationId];
          break;
        default:
          break;
      }
    },
    updateMeasurements: (state, action) => {
      const { actionType, measurementId, measurementData } = action.payload;

      if (!state.selectedCase || !state.selectedCase.items) return;

      if (!state.selectedCase.items.measurements) {
        state.selectedCase.items.measurements = {};
      }

      switch (actionType) {
        case "add":
          state.selectedCase.items.measurements[measurementId] = measurementData;
          break;
        case "update":
          if (state.selectedCase.items.measurements[measurementId]) {
            state.selectedCase.items.measurements[measurementId] = {
              ...state.selectedCase.items.measurements[measurementId],
              ...measurementData,
            };
          }
          break;
        case "delete":
          delete state.selectedCase.items.measurements[measurementId];
          break;
        default:
          break;
      }
    },
  },
});

export const {
  fetchCasesStart,
  fetchCasesSuccess,
  fetchCasesFailure,
  setSelectedCase,
  updateModelInfos,
  updateAnnotations,
  updateMeasurements
} = casesSlice.actions;

export default casesSlice.reducer;
