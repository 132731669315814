import React from "react";
import { useNavigate } from "react-router-dom";
import { signOut } from "firebase/auth";
import { signOutSuccess } from "../../redux/userRedux";
import { auth } from "../../firebase";
import { useDispatch } from "react-redux";

const DropDownMenu = ({ isOpen, onClose }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  if (!isOpen) return null;

  const handleSignOut = async () => {
    await signOut(auth);
    dispatch(signOutSuccess());
    navigate("/home");
  };

  return (
    <div
      className="absolute right-8 w-64 bg-blue-500 text-white shadow-lg rounded-md p-3 z-50"
      style={{ top: "100%" }}
    >
      <ul>
        <li
          onClick={() => navigate("/profilepage")}
          className="hover:bg-blue-700 p-2 cursor-pointer"
        >
          Profile
        </li>
        <li
          onClick={() => navigate("/caselist")}
          className="hover:bg-blue-700 p-2 cursor-pointer"
        >
          Cases
        </li>
        <li
          onClick={handleSignOut}
          className="hover:bg-blue-700 p-2 cursor-pointer"
        >
          Log Out
        </li>
      </ul>
    </div>
  );
};

export default DropDownMenu;
