import React, { useState } from "react";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import SegmentedStructureDeleteConfirmation from "../modals/SegmentedStructureDeleteConfirmation";
import { Roles } from "../../constants/roles";

const ModelFiles = ({
  modelInfos,
  handleDownload,
  handleDelete,
  isMobile,
  userRole,
}) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedFileIndex, setSelectedFileIndex] = useState(null);

  const isViewer = userRole === Roles.VIEWER;

  const confirmDelete = (index) => {
    setSelectedFileIndex(index);
    setShowDeleteModal(true);
  };

  const handleConfirmDelete = () => {
    if (selectedFileIndex !== null) {
      handleDelete(selectedFileIndex);
      setShowDeleteModal(false);
      setSelectedFileIndex(null);
    }
  };

  const handleCloseModal = () => {
    setShowDeleteModal(false);
    setSelectedFileIndex(null);
  };

  if (isMobile) {
    return (
      <div className="space-y-4">
        <h2 className="text-lg font-semibold mb-4">Model Files</h2>
        {modelInfos.map((file, index) => (
          <div key={index} className="border rounded-lg p-4 shadow-md">
            <div className="flex justify-between">
              <p className="font-semibold">Name:</p>
              <p>{file.name}</p>
            </div>
            <div className="flex justify-between">
              <p className="font-semibold">Uploaded By:</p>
              <p>{file.uploadedBy}</p>
            </div>
            <div className="flex justify-between">
              <p className="font-semibold">Upload Date:</p>
              <p>{file.uploadDate}</p>
            </div>
            <div className="flex justify-between">
              <p className="font-semibold">Revision Number:</p>
              <p>{file.revisionNumber || "N/A"}</p>
            </div>
            {!isViewer && (
              <div className="flex justify-between mt-2 space-x-2">
                <button
                  className="px-2 py-1 rounded hover:text-blue-500"
                  onClick={() => handleDownload(file)}
                >
                  <FileDownloadOutlinedIcon />
                </button>
                <button
                  className="px-2 py-1 rounded hover:text-red-500"
                  onClick={() => confirmDelete(index)}
                >
                  <DeleteOutlinedIcon />
                </button>
              </div>
            )}
          </div>
        ))}
        {showDeleteModal && (
          <SegmentedStructureDeleteConfirmation
            onClose={handleCloseModal}
            onConfirm={handleConfirmDelete}
          />
        )}
      </div>
    );
  }

  return (
    <div>
      <h2 className="text-lg font-semibold mb-4">Model Files</h2>
      <table className="w-full border-collapse">
        <thead>
          <tr className="bg-gray-200">
            <th className="p-2 text-center border-l-2 border-l-white">
              Segmented Structure
            </th>
            <th className="p-2 text-center border-l-2 border-l-white">
              Uploaded By
            </th>
            <th className="p-2 text-center border-l-2 border-l-white">
              Upload Date
            </th>
            <th className="p-2 text-center border-l-2 border-l-white">
              Revision Number
            </th>
            {!isViewer && (
              <th className="p-2 text-center border-l-2 border-l-white">
                Actions
              </th>
            )}
          </tr>
        </thead>
        <tbody>
          {modelInfos.map((file, index) => (
            <tr
              key={index}
              className={index % 2 === 0 ? "bg-gray-100" : "bg-white"}
            >
              <td className="p-2 text-center">{file.name}</td>
              <td className="p-2 text-center">{file.uploadedBy}</td>
              <td className="p-2 text-center">{file.uploadDate}</td>
              <td className="p-2 text-center">
                {file.revisionNumber || "1.0"}
              </td>
              {!isViewer && (
                <td className="p-2 text-center">
                  <button
                    className="px-2 py-1 rounded hover:text-blue-500"
                    onClick={() => handleDownload(file)}
                  >
                    <FileDownloadOutlinedIcon />
                  </button>
                  <button
                    className="px-2 py-1 rounded hover:text-red-500"
                    onClick={() => confirmDelete(index)}
                  >
                    <DeleteOutlinedIcon />
                  </button>
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>

      {showDeleteModal && (
        <SegmentedStructureDeleteConfirmation
          onClose={handleCloseModal}
          onConfirm={handleConfirmDelete}
        />
      )}
    </div>
  );
};

export default ModelFiles;
