import React, { useState } from "react";
import { Roles } from "../../constants/roles";

const ShareModal = ({ onClose, onSubmit }) => {
  const [email, setEmail] = useState("");
  const [selectedRole, setSelectedRole] = useState(Roles.VIEWER);

  const handleEmailChange = (e) => setEmail(e.target.value);
  const handleRoleChange = (e) => setSelectedRole(e.target.value);

  const handleSend = () => {
    if (email.trim() && selectedRole) {
      onSubmit(email, selectedRole);
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white p-8 rounded shadow-lg w-full max-w-sm">
        <h2 className="text-xl font-semibold mb-4">Share Case</h2>
        <div className="mb-4">
          <label htmlFor="email" className="block text-sm font-medium text-gray-700">
            Email
          </label>
          <input
            id="email"
            type="email"
            value={email}
            onChange={handleEmailChange}
            className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            placeholder="Enter email address"
          />
        </div>
        <div className="mb-4">
          <label htmlFor="role" className="block text-sm font-medium text-gray-700">
            Role
          </label>
          <select
            id="role"
            value={selectedRole}
            onChange={handleRoleChange}
            className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
          >
            {Object.values(Roles)
              .filter((role) => role !== Roles.ADMIN && role !== Roles.CREATOR)
              .map((role) => (
                <option key={role} value={role}>
                  {role}
                </option>
              ))}
          </select>
        </div>
        <div className="flex justify-end space-x-4">
          <button
            onClick={onClose}
            className="bg-gray-500 text-white px-4 py-2 rounded focus:outline-none focus:ring-2 focus:ring-gray-300 hover:bg-gray-600 transition"
          >
            Cancel
          </button>
          <button
            onClick={handleSend}
            className="bg-blue-500 text-white px-4 py-2 rounded focus:outline-none focus:ring-2 focus:ring-blue-300 hover:bg-blue-600 transition"
            disabled={!email.trim()}
          >
            Add
          </button>
        </div>
      </div>
    </div>
  );
};

export default ShareModal;
