import React from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const SignupInputFields = ({ formData, handleChange }) => {
  const {
    firstName,
    lastName,
    department,
    institutionName,
    number,
    email,
    password,
    confirmPassword,
  } = formData;

  const formatName = (name) =>
    name
      .split(" ")
      .map((part) => part.charAt(0).toUpperCase() + part.slice(1).toLowerCase())
      .join(" ");

  return (
    <>
      <div className="grid grid-cols-1 sm:grid-cols-2 sm:gap-4"> 
        <div className="mb-4"> 
          <label className="block text-gray-500/75 text-sm font-semibold mb-1">
            First Name (*)
          </label>
          <input
            type="text"
            className="w-full px-3 py-2 shadow bg-slate-50 hover:bg-slate-100 focus:bg-slate-100 rounded-md focus:outline-none focus:shadow-sky-300 text-gray-600"
            placeholder="Enter your first name"
            value={firstName}
            onChange={(e) => handleChange("firstName")(formatName(e.target.value))}
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-500/75 text-sm font-semibold mb-1">
            Last Name (*)
          </label>
          <input
            type="text"
            className="w-full px-3 py-2 shadow bg-slate-50 hover:bg-slate-100 focus:bg-slate-100 rounded-md focus:outline-none focus:shadow-sky-300 text-gray-600"
            placeholder="Enter your last name"
            value={lastName}
            onChange={(e) => handleChange("lastName")(formatName(e.target.value))}
          />
        </div>
      </div>
      <div className="mb-4"> 
        <label className="block text-gray-500/75 text-sm font-semibold mb-1">
          Institution Name (*)
        </label>
        <input
          type="text"
          className="w-full px-3 py-2 shadow bg-slate-50 hover:bg-slate-100 focus:bg-slate-100 rounded-md focus:outline-none focus:shadow-sky-300 text-gray-600"
          placeholder="Enter your institution name"
          value={institutionName}
          onChange={(e) => handleChange("institutionName")(e.target.value)}
        />
      </div>
      <div className="mb-4">
        <label className="block text-gray-500/75 text-sm font-semibold mb-1">
          Department (*)
        </label>
        <input
          type="text"
          className="w-full px-3 py-2 shadow bg-slate-50 hover:bg-slate-100 focus:bg-slate-100 rounded-md focus:outline-none focus:shadow-sky-300 text-gray-600"
          placeholder="Enter your department"
          value={department}
          onChange={(e) => handleChange("department")(e.target.value)}
        />
      </div>
      <div className="mb-4">
        <label className="block text-gray-500/75 text-sm font-semibold mb-1">
          Phone Number
        </label>
        <PhoneInput
          value={number}
          onChange={handleChange("number")}
          inputStyle={{ width: "100%" }}
          placeholder="Enter your phone number"
        />
      </div>
      <div className="mb-4">
        <label className="block text-gray-500/75 text-sm font-semibold mb-1">
          Email (*)
        </label>
        <input
          type="email"
          className="w-full px-3 py-2 shadow bg-slate-50 hover:bg-slate-100 focus:bg-slate-100 rounded-md focus:outline-none focus:shadow-sky-300 text-gray-600"
          placeholder="Enter your email"
          value={email}
          onChange={(e) => handleChange("email")(e.target.value)}
        />
      </div>
      <div className="mb-4">
        <label className="block text-gray-500/75 text-sm font-semibold mb-1">
          Password (*)
        </label>
        <input
          type="password"
          className="w-full px-3 py-2 shadow bg-slate-50 hover:bg-slate-100 focus:bg-slate-100 rounded-md focus:outline-none focus:shadow-sky-300 text-gray-600"
          placeholder="Enter your password"
          value={password}
          onChange={(e) => handleChange("password")(e.target.value)}
        />
      </div>
      <div className="mb-4">
        <label className="block text-gray-500/75 text-sm font-semibold mb-1">
          Confirm Password (*)
        </label>
        <input
          type="password"
          className="w-full px-3 py-2 shadow bg-slate-50 hover:bg-slate-100 focus:bg-slate-100 rounded-md focus:outline-none focus:shadow-sky-300 text-gray-600"
          placeholder="Confirm your password"
          value={confirmPassword}
          onChange={(e) => handleChange("confirmPassword")(e.target.value)}
        />
      </div>
    </>
  );
};

export default SignupInputFields;
