import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentUser } from "../../redux/userRedux";
import { database, storage } from "../../firebase";
import { ref, onValue, off, remove, get, set } from "firebase/database";
import { ref as storageRef, deleteObject } from "firebase/storage";
import { useNavigate } from "react-router-dom";
import useIsMobile from "../../hooks/useIsMobile";
import CaseTable from "./CaseTable";
import DeleteConfirmationModal from "../modals/DeleteConfirmationModal";
import CaseSearchBar from "./CaseSearchBar";
import MobileCaseTable from "./MobileCaseTable";
import { setSelectedCase } from "../../redux/casesRedux";

const CaseListPageView = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);

  const [caseList, setCaseList] = useState([]);
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "ascending",
  });
  const [showDeleteButtons, setShowDeleteButtons] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [caseToDelete, setCaseToDelete] = useState(null);

  const isMobile = useIsMobile();

  useEffect(() => {
    if (user.isSignedIn) {
      const userRef = ref(database, `users/${user.currentUid}`);
      onValue(userRef, (snapshot) => {
        const data = snapshot.val();
        dispatch(setCurrentUser(data));
      });

      return () => {
        off(userRef);
      };
    }
  }, [dispatch, user.currentUid, user.isSignedIn]);

  useEffect(() => {
    const fetchCases = async () => {
      if (user.currentUser?.cases) {
        const caseIds = Object.values(user.currentUser.cases);
        const fetchedCases = [];

        try {
          for (const caseId of caseIds) {
            const caseRef = ref(database, `cases/${caseId}`);
            const snapshot = await get(caseRef);

            if (snapshot.exists()) {
              const caseData = snapshot.val();
              fetchedCases.push({
                id: caseId,
                ...caseData,
                dueDate: new Date(caseData.dueDate).toLocaleDateString(),
                creationDate: new Date(
                  caseData.creationDate
                ).toLocaleDateString(),
                expirationDate: new Date(
                  caseData.expirationDate
                ).toLocaleDateString(),
              });
            }
          }

          fetchedCases.sort((a, b) => a.title.localeCompare(b.title));
          setCaseList(fetchedCases);
        } catch (error) {
          console.error("Error fetching cases:", error);
        }
      } else {
        setCaseList([]);
      }
    };

    fetchCases();
  }, [user.currentUser]);

  const handleDelete = async () => {
    if (!caseToDelete) return;

    try {
      const caseRef = ref(database, `cases/${caseToDelete}`);
      await remove(caseRef);

      const newCasesListForUser = user.currentUser.cases.filter(
        (caseId) => caseId !== caseToDelete
      );
      const userRef = ref(database, `users/${user.currentUid}/cases`);

      await set(userRef, newCasesListForUser);

      const fullCaseToDelete = caseList.find(
        (caseItem) => caseItem.id === caseToDelete
      );

      if (
        fullCaseToDelete.items &&
        fullCaseToDelete.items.modelInfos.length > 0
      ) {
        const deletePromises = fullCaseToDelete.items.modelInfos.map(
          async (info) => {
            const fileRef = storageRef(
              storage,
              `cases/${caseToDelete}/stlFiles/${info.storageName}`
            );
            await deleteObject(fileRef);
          }
        );

        await Promise.all(deletePromises);
      }

      setCaseList((prevCaseList) =>
        prevCaseList.filter((caseItem) => caseItem.id !== caseToDelete)
      );

      setCaseToDelete(null);
      setIsModalOpen(false);
      setShowDeleteButtons(false);
    } catch (err) {
      console.error("Error deleting case:", err);
    }
  };

  const requestSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  const getSortDirection = (key) =>
    sortConfig.key === key
      ? sortConfig.direction === "ascending"
        ? "▲"
        : "▼"
      : "";

  const sortedCaseList = [...caseList]
    .filter((caseItem) =>
      caseItem.title?.toLowerCase().includes(searchQuery.toLowerCase())
    )
    .sort((a, b) => {
      if (!a[sortConfig.key] || !b[sortConfig.key]) return 0;
      return sortConfig.direction === "ascending"
        ? a[sortConfig.key].localeCompare(b[sortConfig.key])
        : b[sortConfig.key].localeCompare(a[sortConfig.key]);
    });

  const openModal = (caseId) => {
    setCaseToDelete(caseId);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setCaseToDelete(null);
  };

  const handleAddCase = () => {
    dispatch(setSelectedCase(null));
    navigate("/addoreditcasepage");
  };

  return (
    <div className={isMobile ? "p-4" : "p-8"}>
      <h2 className="text-lg font-semibold mb-4">Case List</h2>
      <CaseSearchBar
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        onAddCase={handleAddCase}
        toggleDeleteMode={() => setShowDeleteButtons(!showDeleteButtons)}
        isDeleteModeActive={showDeleteButtons}
        isMobile={isMobile}
      />
      {isMobile ? (
        <MobileCaseTable
          sortedCaseList={sortedCaseList}
          navigate={navigate}
          showDeleteButtons={showDeleteButtons}
          openModal={openModal}
          user={user.currentUser}
        />
      ) : (
        <CaseTable
          caseList={sortedCaseList}
          requestSort={requestSort}
          getSortDirection={getSortDirection}
          showDeleteButtons={showDeleteButtons}
          openModal={openModal}
          navigate={navigate}
          user={user.currentUser}
        />
      )}
      {isModalOpen && (
        <DeleteConfirmationModal
          isOpen={isModalOpen}
          closeModal={closeModal}
          handleDelete={handleDelete}
        />
      )}
    </div>
  );
};

export default CaseListPageView;
